import { teams } from './pages/tl25/teams';
import { Routes } from './routes';
import { MenuItem } from './types/menu';

export const menuItems: MenuItem[] = [
  {
    title: 'Team League',
    opened: true,
    submenu: [
      {
        title: 'Základní část',
        submenu: [
          {
            title: 'Přehled',
            url: Routes.TL25.routes.OVERVIEW.path,
          },
          {
            title: 'Týmy',
            submenu: [
              {
                title: teams.Vaclavson.name,
                url: Routes.TL25.routes.TEAM_DETAIL_VACLAVSON.path,
              },
              {
                title: teams.Nolimit.name,
                url: Routes.TL25.routes.TEAM_DETAIL_NOLIMIT.path,
              },
              {
                title: teams.Tomcher.name,
                url: Routes.TL25.routes.TEAM_DETAIL_TOMCHER.path,
              },
              {
                title: teams.BaB.name,
                url: Routes.TL25.routes.TEAM_DETAIL_BAB.path,
              },
              {
                title: teams.Surgen.name,
                url: Routes.TL25.routes.TEAM_DETAIL_SURGEN.path,
              },
              {
                title: teams.Tachometr.name,
                url: Routes.TL25.routes.TEAM_DETAIL_TACHOMETR.path,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: '5. sezóna',
    opened: false,
    submenu: [
      {
        title: 'Offline finále',
        submenu: [
          {
            title: 'Informace',
            url: Routes.S5_FINALS.path,
          },
          {
            title: 'Skupina A',
            url: Routes.S5_FINALS_GROUP_A.path,
          },
          {
            title: 'Skupina B',
            url: Routes.S5_FINALS_GROUP_B.path,
          },
          {
            title: 'Play-off',
            url: Routes.S5_FINALS_PLAYOFF.path,
          },
        ],
      },
      {
        title: 'Předkolo finále',
        submenu: [
          {
            title: 'Red group',
            url: Routes.S5_PRELIMINARY_RED.path,
          },
          {
            title: 'Blue group',
            url: Routes.S5_PRELIMINARY_BLUE.path,
          },
        ],
      },
      {
        title: 'Základní část',
        url: Routes.S5_GROUP_STAGE.path,
        disabled: true,
      },
      {
        title: 'Rookie tour',
        submenu: [
          {
            title: 'Skupina A',
            url: Routes.S5_ROOKIE_TOUR_GROUP_A.path,
          },
          {
            title: 'Skupina B',
            url: Routes.S5_ROOKIE_TOUR_GROUP_B.path,
          },
          {
            title: 'Play-off',
            url: Routes.S5_ROOKIE_TOUR_PLAYOFF.path,
          },
        ],
      },
    ],
  },
  // {
  //   url: '/#next-matches',
  //   title: 'Příští zápasy',
  //   disabled: true,
  // },
  // {
  //   anchor: 'czsk-liga',
  //   title: 'CZSK liga',
  //   disabled: true,
  // },
  // {
  //   anchor: 'watch-liga',
  //   title: 'Sleduj ligu',
  //   disabled: true,
  // },
  // {
  //   anchor: 'join-us',
  //   title: 'Připoj se!',
  //   disabled: true,
  // },
];
