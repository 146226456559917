import { FC } from 'react';
import clsx from 'clsx';

import { TeamMatchType } from '../../../types/match';
import { ShowScore } from '../constants';

import styles from './MatchList.module.scss';
import { Match } from './Match';

type MatchListProps = {
  matches: TeamMatchType[];
  description?: string[];
  showScore?: ShowScore;
  expandable?: boolean;
  fullName?: boolean;
  className?: string;
  matchClassName?: string;
};

export const MatchList: FC<MatchListProps> = ({
  matches,
  description,
  showScore,
  expandable,
  fullName,
  className,
  matchClassName,
}) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {matches.map((match, index) => (
        <li key={index}>
          <Match
            {...match}
            className={matchClassName}
            description={description}
            showScore={showScore}
            expandable={expandable && !!match.matches?.length}
            fullName={fullName}
          />
        </li>
      ))}
    </ul>
  );
};
