import { FC } from 'react';
import { SocialIcon } from 'react-social-icons';
import clsx from 'clsx';

import styles from './Socials.module.scss';

const links = [
  'https://discord.gg/RfdXdgfR',
  'https://www.facebook.com/starcraftczsk',
  'https://www.youtube.com/@czsk-starcraft',
  'https://www.twitch.tv/censured',
];

type SocialsProps = {
  className?: string;
};

const Socials: FC<SocialsProps> = ({ className }) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {links.map((url, i) => (
        <li key={i}>
          <SocialIcon url={url} target={url} />
        </li>
      ))}
    </ul>
  );
};

export default Socials;
