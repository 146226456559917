import { useModal } from '../components/Modal';
import styles from '../styles/videoModal.module.scss';

export const useVideoModal = () => {
  const { open } = useModal();

  const showVideoModal = (videoId: string, startTime?: number) => {
    open({
      content: (
        <div className={styles.iframeWrapper}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}${startTime ? `?start=${startTime}` : ''}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className={styles.video}
          ></iframe>
        </div>
      ),
    });
  };

  return showVideoModal;
};
