// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Juo2cOMvCiBA6GjbnQN9 {
  font-size: 1.25rem;
  text-align: center;
  text-transform: uppercase;
}

.r5GE6dZPwdYtZi1hglSf {
  font-size: 2.5rem;
  font-weight: 900;
  color: red;
  animation: bUfx5XFenR1NdmTc967v 1.5s infinite;
}
@keyframes bUfx5XFenR1NdmTc967v {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.DO3AUe10w0VnSM9nOb6X {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: max-content;
}

.nEWjd0J6iqdajueqaHiK {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
}

.rMOp9iNXz7_px14ubznf {
  display: block;
  font-weight: 600;
  color: #12e6fb;
  font-size: 1.4rem;
  margin-bottom: 0.25em;
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/StreamCountdown.module.scss","webpack://./src/styles/conf/_colors.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,kBAAA;EACA,yBAAA;AAHF;;AAMA;EACE,iBAAA;EACA,gBAAA;EACA,UAAA;EACA,6CAAA;AAHF;AAKE;EACE;IACE,UAAA;EAHJ;EAKE;IACE,YAAA;EAHJ;EAKE;IACE,UAAA;EAHJ;AACF;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,sBAAA;AAJF;;AAOA;EACE,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,sBAAA;AAJF;;AAOA;EACE,cAAA;EACA,gBAAA;EACA,cCxCa;EDyCb,iBAAA;EACA,qBAAA;AAJF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Juo2cOMvCiBA6GjbnQN9`,
	"live": `r5GE6dZPwdYtZi1hglSf`,
	"pulse": `bUfx5XFenR1NdmTc967v`,
	"content": `DO3AUe10w0VnSM9nOb6X`,
	"countdown": `nEWjd0J6iqdajueqaHiK`,
	"number": `rMOp9iNXz7_px14ubznf`
};
export default ___CSS_LOADER_EXPORT___;
