import { FC } from 'react';
import type { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import clsx from 'clsx';

import { inflex } from '../../../utils';

import styles from './StreamCountdown.module.scss';

type StreamCountdownProps = CountdownRenderProps;

export const StreamCountdown: FC<StreamCountdownProps> = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <div className={styles.content}>
      {completed ? (
        <div className={clsx(styles.title, styles.live)}>Živě</div>
      ) : (
        <>
          <div className={styles.title}>příští stream</div>
          <div className={styles.countdown}>
            {days > 0 && (
              <span>
                <span className={styles.number}>{days}</span>
                {inflex(days, ['den', 'dny', 'dní'])}
              </span>
            )}
            <span>
              <span className={styles.number}>{hours}</span>
              {inflex(hours, ['hodina', 'hodiny', 'hodin'])}
            </span>
            <span>
              <span className={styles.number}>{minutes}</span>
              {inflex(minutes, ['minuta', 'minuty', 'minut'])}
            </span>
            {days === 0 && (
              <span>
                <span className={styles.number}>{seconds}</span>
                {inflex(seconds, ['sekunda', 'sekundy', 'sekund'])}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
