// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/match_bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hEVpqboKK3ARZyVmdXav {
  aspect-ratio: 3.914893617;
  margin-top: 80px;
  display: flex;
  width: 100%;
  max-width: 552px;
  position: relative;
  z-index: 1;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100%;
}
.hEVpqboKK3ARZyVmdXav::before {
  content: "";
  aspect-ratio: 12/89;
  position: absolute;
  left: -2.1739130435%;
  bottom: 0;
  width: 2.1739130435%;
  background-color: var(--match-color, #000);
}

.Mwqvni_DoZnlW0FJ6FHC {
  box-shadow: -1rem -1rem 2rem #000;
}

.gnwEJIRH7itiwA9s33mu {
  box-shadow: -1rem 1rem 2rem #000;
}
.gnwEJIRH7itiwA9s33mu::before {
  bottom: unset;
  top: 0;
}

.ZcD_IjYFKbBjE8Dl9oZ3 {
  flex: 1;
  flex-basis: calc(50% - 2rem);
  align-self: flex-end;
  display: grid;
  height: 100%;
  grid-template-rows: 30% 70%;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1.5rem;
  text-align: center;
  color: #000;
  padding-left: 2rem;
  color: var(--match-player-color, #000);
}
@media only screen and (max-width: 479.9px) {
  .ZcD_IjYFKbBjE8Dl9oZ3 {
    padding-left: 1rem;
    column-gap: 1rem;
  }
}

.ZPgZly8OLEisCEXzpO4y {
  column-gap: 0;
}

.LqSIJU9TEpYdrUWR9Xlv {
  font-family: "Segment A Key Trial", sans-serif;
  font-weight: 900;
  line-height: 0.4;
  font-size: 5rem;
  text-transform: uppercase;
  grid-column: 2;
  grid-row: 2;
  align-self: self-start;
}
@media only screen and (max-width: 479.9px) {
  .LqSIJU9TEpYdrUWR9Xlv {
    font-size: 3.5rem;
  }
}

.iWyVHXMWSP9rdhM9AaMB {
  text-transform: uppercase;
  font-style: oblique;
  font-size: 0.8rem;
  font-weight: 700;
  grid-column: 2;
  grid-row: 1;
  align-self: self-end;
}

.o8wDz4az3XTZFtY0Na64 {
  font-family: var(--font-cool);
  grid-column: 1;
  grid-row: span 2;
  color: var(--match-score-color, #000);
  font-size: 4rem;
}
@media only screen and (max-width: 479.9px) {
  .o8wDz4az3XTZFtY0Na64 {
    font-size: 3rem;
  }
}

.UBGMVxMccKMhdqRW0tlS {
  color: var(--match-score-winner-color);
}

.st6ishPH9sp1IRYnz8gP {
  flex: 1;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  color: var(--match-color, #000);
}

.mUFYHVhNOOOKWEBpAW3y {
  height: calc(100% + 80px);
  position: absolute;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
}
.mUFYHVhNOOOKWEBpAW3y img {
  max-width: 100%;
  max-height: 160%;
}

.GpiE90y8UNB3DB9zW6EA {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Match/MatchBox.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAeA;EACE,yBAAA;EACA,gBANe;EAOf,aAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EAGE,yDAAA;EACA,4BAAA;EACA,2BAAA;EACA,qBAAA;AAhBJ;AAmBE;EAGE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,oBAAA;EACA,SAAA;EACA,oBAPI;EAQJ,0CAAA;AAnBJ;;AAuBA;EACE,iCAAA;AApBF;;AAuBA;EACE,gCAAA;AApBF;AAsBE;EACE,aAAA;EACA,MAAA;AApBJ;;AAwBA;EACE,OAAA;EACA,4BAAA;EACA,oBAAA;EACA,aAAA;EACA,YApDc;EAqDd,2BAAA;EACA,+BAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;AArBF;ACJY;EDYZ;IAgBI,kBAAA;IACA,gBAAA;EApBF;AACF;;AAuBA;EACE,aAAA;AApBF;;AAuBA;EE7EE,8CAAA;EF+EA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,cAAA;EACA,WAAA;EACA,sBAAA;AApBF;ACzBY;EDqCZ;IAWI,iBAAA;EAnBF;AACF;;AAsBA;EACE,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;EACA,oBAAA;AAnBF;;AAsBA;EACE,6BAAA;EACA,cAAA;EACA,gBAAA;EACA,qCAAA;EACA,eAAA;AAnBF;AChDY;ED8DZ;IAQI,eAAA;EAlBF;AACF;;AAqBA;EACE,sCAAA;AAlBF;;AAqBA;EACE,OAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,+BAAA;AAlBF;;AAqBA;EACE,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;AAlBF;AAoBE;EACE,eAAA;EACA,gBAAA;AAlBJ;;AAsBA;EACE,WAAA;EACA,YAzIc;EA0Id,kBAAA;EACA,SAAA;EACA,OAAA;AAnBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `hEVpqboKK3ARZyVmdXav`,
	"top": `Mwqvni_DoZnlW0FJ6FHC`,
	"bottom": `gnwEJIRH7itiwA9s33mu`,
	"player": `ZcD_IjYFKbBjE8Dl9oZ3`,
	"noScore": `ZPgZly8OLEisCEXzpO4y`,
	"nick": `LqSIJU9TEpYdrUWR9Xlv`,
	"race": `iWyVHXMWSP9rdhM9AaMB`,
	"score": `o8wDz4az3XTZFtY0Na64`,
	"winner": `UBGMVxMccKMhdqRW0tlS`,
	"photo": `st6ishPH9sp1IRYnz8gP`,
	"img": `mUFYHVhNOOOKWEBpAW3y`,
	"czsk": `GpiE90y8UNB3DB9zW6EA`
};
export default ___CSS_LOADER_EXPORT___;
