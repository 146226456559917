import clsx from 'clsx';
import { FC } from 'react';

import person from '../../assets/images/person.webp';
import { PlayerDetailType } from '../../types/players';

import styles from './styles.module.scss';

type PlayerProps = PlayerDetailType & { className?: string };

const Player: FC<PlayerProps> = ({ nick, rank, race, stats, image, content, className }) => {
  return (
    <div className={clsx(styles.wrapper, styles[race], className)} data-rank={rank ? `#${rank}` : undefined}>
      {rank !== undefined && <span className="visually-hidden">Rank {rank}</span>}
      <img src={image ?? person} className={styles.photo} />
      <span className="visually-hidden">{race}</span>
      <span className={styles.nick}>{nick}</span>
      {!content && stats && (
        <ul className={clsx(styles.content, styles.stats)}>
          {stats.map((stat, i) => (
            <li key={i} className={styles.statsRow}>
              vs {stat.vs}
              <dl className={styles.score}>
                <dt className="visually-hidden">Wins</dt>
                <dd>{stat.wins}</dd>
                <dt className="visually-hidden">Losses</dt>
                <dd>{stat.losses}</dd>
              </dl>
            </li>
          ))}
        </ul>
      )}
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
};

export default Player;
