import { FC } from 'react';

type CZSKSvgProps = {
  className?: string;
  width?: number;
  height?: number;
};

const CZSKSvg: FC<CZSKSvgProps> = ({ className, width = 922, height = 431 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        // imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        // opacity: 0.981,
      }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      viewBox="0 0 922 431"
    >
      <path
        fill="currentColor"
        d="M 60.5,42.5 C 118.171,42.4641 175.838,42.9641 233.5,44C 240.61,46.7158 244.444,51.8825 245,59.5C 245.5,99.832 245.667,140.165 245.5,180.5C 222.701,173.734 200.201,166.067 178,157.5C 177.5,141.837 177.333,126.17 177.5,110.5C 160.833,110.5 144.167,110.5 127.5,110.5C 127.5,175.833 127.5,241.167 127.5,306.5C 144.167,306.5 160.833,306.5 177.5,306.5C 177.5,283.833 177.5,261.167 177.5,238.5C 200.102,245.701 222.436,253.701 244.5,262.5C 245.667,294.161 245.833,325.828 245,357.5C 244.507,365.664 240.34,370.83 232.5,373C 175.168,373.5 117.834,373.667 60.5,373.5C 60.5,263.167 60.5,152.833 60.5,42.5 Z"
      />
      <path
        fill="currentColor"
        d="M 268.5,43.5 C 326.837,43.1667 385.17,43.5 443.5,44.5C 416.167,131.5 388.833,218.5 361.5,305.5C 388.825,306.5 416.159,306.833 443.5,306.5C 443.5,328.833 443.5,351.167 443.5,373.5C 385.163,373.833 326.83,373.5 268.5,372.5C 296.608,285.342 324.608,198.176 352.5,111C 324.502,110.5 296.502,110.333 268.5,110.5C 268.5,88.1667 268.5,65.8333 268.5,43.5 Z"
      />
      <path
        fill="currentColor"
        d="M 467.5,43.5 C 525.501,43.3333 583.501,43.5 641.5,44C 646.333,46.1667 649.833,49.6667 652,54.5C 652.833,95.8374 652.667,137.171 651.5,178.5C 629.592,168.629 607.758,158.629 586,148.5C 585.5,135.838 585.333,123.171 585.5,110.5C 568.833,110.5 552.167,110.5 535.5,110.5C 535.175,126.411 535.509,142.244 536.5,158C 574.833,176.833 613.167,195.667 651.5,214.5C 651.833,239.167 652.167,263.833 652.5,288.5C 652.653,314.227 652.153,339.894 651,365.5C 648.313,369.194 644.813,371.694 640.5,373C 583.167,373.667 525.833,373.667 468.5,373C 467.503,327.905 467.17,282.739 467.5,237.5C 489.921,247.542 512.254,257.876 534.5,268.5C 535.499,281.149 535.832,293.816 535.5,306.5C 552.167,306.5 568.833,306.5 585.5,306.5C 585.833,290.486 585.499,274.486 584.5,258.5C 548.167,240.667 511.833,222.833 475.5,205C 473,203.833 471.167,202 470,199.5C 468.701,178.898 468.034,158.231 468,137.5C 467.5,106.168 467.333,74.8351 467.5,43.5 Z"
      />
      <path
        fill="currentColor"
        d="M 686.5,43.5 C 709.167,43.5 731.833,43.5 754.5,43.5C 754.333,91.5012 754.5,139.501 755,187.5C 770.936,139.859 786.769,92.1926 802.5,44.5C 825.833,43.1667 849.167,43.1667 872.5,44.5C 854.3,99.0987 836.3,153.765 818.5,208.5C 836.3,263.235 854.3,317.901 872.5,372.5C 849.176,373.5 825.843,373.833 802.5,373.5C 786.975,325.425 771.142,277.425 755,229.5C 754.667,277.167 754.333,324.833 754,372.5C 731.667,373.833 709.333,373.833 687,372.5C 686.5,262.834 686.333,153.167 686.5,43.5 Z"
      />
    </svg>
  );
};

export default CZSKSvg;
