// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/twitch.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YVvVxjvR10IHAedq0YNy {
  background-color: rgb(30, 28, 29);
  padding: 1.5rem 0 2rem;
  position: relative;
}
.YVvVxjvR10IHAedq0YNy::before {
  content: "";
  width: 100%;
  height: 2.2rem;
  background-color: rgba(30, 28, 29, 0.55);
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.mlYYDqiLYCOJKnRBCbhL {
  display: grid;
  justify-content: space-between;
  gap: 2rem 1rem;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 1280px) {
  .mlYYDqiLYCOJKnRBCbhL {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1500px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1280px) {
  .BmssDxKKzE4OKDicuoZv {
    grid-column: 1/4;
  }
}

.vjP16drt5TrkRkpy78SW {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.25;
}

.FwAJQ3yC2zX1Hi9W37tT {
  font-size: 1.25rem;
  text-transform: none;
  white-space: pre-wrap;
}

.MduxSpT2L46y4M9tc6QP {
  color: #12e6fb;
  font-weight: 600;
}
.MduxSpT2L46y4M9tc6QP::before {
  content: "\\a";
  white-space: pre;
}

.zNcrUUM61LnSz_IrDXDD {
  display: flex;
  justify-content: center;
  align-items: center;
}

.JJ8tgLWfUG4s_xXSdKKJ {
  display: flex;
  align-content: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), linear-gradient(to right, #6340a4, #8e50e1);
  background-size: auto 50%, cover;
  background-position: 1rem 50%, 0 0;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  padding: 1rem 1rem 1rem 3.5rem;
  font-size: 2rem;
  font-weight: 800;
}
@media only screen and (min-width: 1280px) {
  .JJ8tgLWfUG4s_xXSdKKJ {
    transition: opacity 0.4s, border-color 0.4s;
  }
}
.JJ8tgLWfUG4s_xXSdKKJ:hover, .JJ8tgLWfUG4s_xXSdKKJ:focus {
  text-decoration: none;
  border-color: var(--button-link-border-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/Stream.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/conf/_colors.scss"],"names":[],"mappings":"AAKA;EACE,iCAAA;EACA,sBAAA;EACA,kBAAA;AAJF;AAME;EACE,WAAA;EACA,WAAA;EACA,cAAA;EACA,wCAAA;EACA,kBAAA;EACA,MAAA;EACA,4BAAA;AAJJ;;AAQA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;AALF;ACQU;EDRV;IAQI,kCAAA;IACA,iBAAA;IACA,cAAA;EAJF;AACF;;ACCU;EDMV;IAEI,gBAAA;EAJF;AACF;;AAOA;EACE,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,iBAAA;AAJF;;AAOA;EACE,kBAAA;EACA,oBAAA;EACA,qBAAA;AAJF;;AAOA;EACE,cE7Ca;EF8Cb,gBAAA;AAJF;AAME;EACE,aAAA;EACA,gBAAA;AAJJ;;AAQA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AALF;;AAQA;EAGE,aAAA;EACA,qBAAA;EAEE,sGAAA;EACA,gCAAA;EACA,kCAAA;EACA,4BAAA;EAEF,6BAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;AATF;AC9CU;EDyCV;IAiBI,2CACE;EATJ;AACF;AAYE;EAEE,qBAAA;EACA,6CAAA;AAXJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `YVvVxjvR10IHAedq0YNy`,
	"content": `mlYYDqiLYCOJKnRBCbhL`,
	"fullWidth": `BmssDxKKzE4OKDicuoZv`,
	"text": `vjP16drt5TrkRkpy78SW`,
	"description": `FwAJQ3yC2zX1Hi9W37tT`,
	"highlight": `MduxSpT2L46y4M9tc6QP`,
	"item": `zNcrUUM61LnSz_IrDXDD`,
	"button": `JJ8tgLWfUG4s_xXSdKKJ`
};
export default ___CSS_LOADER_EXPORT___;
