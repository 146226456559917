import clsx from 'clsx';

import LinkList from '../../components/LinkList';
import MatchListPhases from '../../components/MatchListPhases';
import matchList from '../../data/s5/rookie/playOff.json';
import { Routes } from '../../routes';
import generalStyles from '../../styles/page.module.scss';
import { MatchType } from '../../types/match';
import { parseMatches } from '../../utils';
import Footer from '../../components/Footer';
import LinkTwitch from '../../components/LinkTwitch';

import { players } from './players';
import styles from './styles.module.scss';

const matches: Record<string, MatchType[]> = Object.fromEntries(
  Object.keys(matchList).map((phaseTitle) => [
    phaseTitle,
    parseMatches(matchList[phaseTitle as keyof typeof matchList], players),
  ]),
);

const eventStart = new Date('2024-11-13T19:00:00+01:00');
const eventEnd = new Date('2024-11-13T21:00:00+01:00');

export const RookieTourPlayoff = () => {
  const now = new Date();
  const isStreamLive = now >= eventStart && now <= eventEnd;
  return (
    <main
      className={clsx(
        generalStyles.page,
        generalStyles.czskLeagueText,
        generalStyles.czskLeagueTextFixed,
        styles.greenColors,
      )}
    >
      <div className={styles.content}>
        <header className={generalStyles.pageSectionTitle}>Rookie tour</header>
        <div className={styles.container}>
          <h1 className={generalStyles.title}>
            Pla<span className={generalStyles.emptyChar}></span>y-off
          </h1>
          <LinkList
            items={[
              <LinkTwitch
                key="CZSKStarcraft"
                label="CZSKStarcraft"
                to="https://www.twitch.tv/CZSKStarcraft"
                isLive={isStreamLive}
                target="https://www.twitch.tv/CZSKStarcraft"
                topContent={eventStart.toLocaleString('cs-CZ', {
                  timeZone: 'Europe/Prague',
                  timeZoneName: 'short',
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              />,
            ]}
          />
          <MatchListPhases matches={matches} />
          <div className={styles.videoWrapper}>
            <h3 className={clsx(generalStyles.subheading, generalStyles.subheadingShade)}>Záznam</h3>
            <div className={styles.iframeWrapper}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fmwp2UwPeVM"
                title="CZSK StarCraft League - Season 5 - Rookie tour - Čtvtfinále - 1. zápas"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className={styles.video}
              ></iframe>
            </div>
            <div className={styles.iframeWrapper}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/RLp5Z6N9LvY"
                title="CZSK StarCraft League - Season 5 - Rookie tour - Čtvtfinále - 2. zápas"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className={styles.video}
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <LinkList
        items={[
          { name: 'Skupina A', to: Routes.S5_ROOKIE_TOUR_GROUP_A.path },
          { name: 'Skupina B', to: Routes.S5_ROOKIE_TOUR_GROUP_B.path },
        ]}
      />
      <Footer />
    </main>
  );
};
