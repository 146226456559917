import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import btnStyles from '../styles/button.module.scss';

import styles from './LinkTwitch.module.scss';

type LinkTwitchProps = {
  to: string;
  label: string;
  className?: string;
  target?: string;
  isLive?: boolean;
  topContent?: ReactNode;
  bottomContent?: ReactNode;
};

const LinkTwitch: FC<LinkTwitchProps> = ({ label, to, className, target, isLive, topContent, bottomContent }) => {
  return (
    <div className={styles.wrapper}>
      {topContent && <p className={styles.content}>{topContent}</p>}
      <Link
        to={to}
        className={clsx(btnStyles.button, styles.linkTwitch, className, {
          [styles.linkTwitchLive]: isLive,
        })}
        target={target}
      >
        {label}
      </Link>
      {bottomContent && <p className={styles.content}>{bottomContent}</p>}
    </div>
  );
};

export default LinkTwitch;
