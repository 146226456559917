import { FC } from 'react';
import Countdown from 'react-countdown';
import type { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useStats } from '../StatsContext';

import styles from './Stream.module.scss';
import { StreamCountdown } from './StreamCountdown';

const renderer = (props: CountdownRenderProps) => {
  return <StreamCountdown {...props} />;
};

const getStreamDateText = (date: Date) => {
  const today = new Date();

  if (date.getDate() === today.getDate()) {
    return 'dnes';
  }

  const days = ['neděli', 'pondělí', 'úterý', 'středu', 'čtvrtek', 'pátek', 'sobotu'];
  const dayOfWeek = date.getDay();
  const dayName = days[dayOfWeek];

  const upcomingSunday = new Date();
  upcomingSunday.setDate(today.getDate() + (7 - today.getDay()));
  upcomingSunday.setHours(23, 59, 59);

  if (date <= upcomingSunday) {
    const thisWeekPrefix = ['tuto', 'toto', 'toto', 'tuto', 'tento', 'tento', 'tuto'];
    return `${thisWeekPrefix[dayOfWeek]} ${dayName}`;
  }

  const nextWeekSunday = new Date();
  nextWeekSunday.setDate(upcomingSunday.getDate() + 7);
  nextWeekSunday.setHours(23, 59, 59);

  if (date <= nextWeekSunday) {
    return `příští ${dayName}`;
  }
  return date.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric', weekday: 'long' });
};

export const Stream: FC = () => {
  const {
    stream: { date: isoDate, link, description, isInFuture: showCountdown },
  } = useStats();
  const date = isoDate ? new Date(isoDate) : null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>
          Sleduj stream{' '}
          {showCountdown ? (
            <>
              <strong className={styles.highlight}>{getStreamDateText(date!)}</strong> od {date!.getHours()}:
              {date && String(date.getMinutes()).padStart(2, '0')}{' '}
            </>
          ) : (
            <>
              <strong className={styles.highlight}>každou středu</strong> od 20:00
            </>
          )}
        </div>
        <div className={styles.item}>
          <Link to={link} className={styles.button} target="#tl25live">
            CZSK StarCraft
          </Link>
        </div>
        <div className={styles.item}>{showCountdown && <Countdown date={date!} renderer={renderer} />}</div>
        {description && (
          <div className={clsx(styles.item, styles.text, styles.description, styles.fullWidth)}>
            {description.trim().replace(/\n+/g, '\n')}
          </div>
        )}
      </div>
    </div>
  );
};
