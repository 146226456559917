import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to anchor if hash is present in the URL
export const useScrollToHash = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) return;
    const element = document.querySelector(location.hash);
    if (!element) return;
    window.setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth' });
    }, 0); // Slight delay to ensure the element is loaded before scrolling
  }, [location]);

  return null;
};
