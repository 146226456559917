import { Routes } from './routes';
import { MenuItem } from './types/menu';

export const menuItems: MenuItem[] = [
  {
    title: '5. sezóna',
    opened: true,
    submenu: [
      {
        title: 'Offline finále',
        submenu: [
          {
            title: 'Informace',
            url: Routes.S5_FINALS.path,
          },
          {
            title: 'Skupina A',
            url: Routes.S5_FINALS_GROUP_A.path,
          },
          {
            title: 'Skupina B',
            url: Routes.S5_FINALS_GROUP_B.path,
          },
          {
            title: 'Play-off',
            url: Routes.S5_FINALS_PLAYOFF.path,
          },
        ],
      },
      {
        title: 'Předkolo finále',
        submenu: [
          {
            title: 'Red group',
            url: Routes.S5_PRELIMINARY_RED.path,
          },
          {
            title: 'Blue group',
            url: Routes.S5_PRELIMINARY_BLUE.path,
          },
        ],
      },
      {
        title: 'Základní část',
        url: Routes.S5_GROUP_STAGE.path,
        disabled: true,
      },
      {
        title: 'Rookie tour',
        submenu: [
          {
            title: 'Skupina A',
            url: Routes.S5_ROOKIE_TOUR_GROUP_A.path,
          },
          {
            title: 'Skupina B',
            url: Routes.S5_ROOKIE_TOUR_GROUP_B.path,
          },
          {
            title: 'Play-off',
            url: Routes.S5_ROOKIE_TOUR_PLAYOFF.path,
          },
        ],
      },
    ],
  },
  // {
  //   url: '/#next-matches',
  //   title: 'Příští zápasy',
  //   disabled: true,
  // },
  // {
  //   anchor: 'czsk-liga',
  //   title: 'CZSK liga',
  //   disabled: true,
  // },
  // {
  //   anchor: 'watch-liga',
  //   title: 'Sleduj ligu',
  //   disabled: true,
  // },
  // {
  //   anchor: 'join-us',
  //   title: 'Připoj se!',
  //   disabled: true,
  // },
];
