// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Redzone-BoldCond.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Redzone-BoldSemiCond.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Redzone-BoldSemiExtd.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Redzone-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Redzone-MediumExtd.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/Redzone-MediumSemiCond.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/VTFRedzone-Classic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./fonts/VTFRedzone-ClassicOblique.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./fonts/SegmentAKeyTrial-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./fonts/SegmentAKeyTrial-Black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./fonts/SegmentAKeyTrial-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;600;700;900&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Import Google Font */
/* Define custom fonts */
@font-face {
  font-family: "Redzone Bold Condensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Bold SemiCondensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Bold SemiExtended";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Medium Extended";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Medium SemiCondensed";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Redzone Classic Caps";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Redzone Classic Caps";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Segment A Key Trial";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segment A Key Trial";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Segment A Key Trial";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("truetype");
  font-weight: 400;
  font-style: normal;
}
:root {
  --primary-color: #001f5b;
  --secondary-color: #e63946;
  --background-color: #111;
  --text-color: #ffffff;
  --disabled-color: #666;
  --accent-color: #0077b6;
  --font-size-small: 0.75em;
  --font-size-medium: 1em;
  --font-size-large: 1.5em;
  --font-size-large2: 1.8em;
  --font-size-xlarge: 2em;
  --font-size-xzlarge: 2.2em;
  --padding: 1em;
  --margin: 1em;
  --font-cool: "Redzone Bold Condensed", sans-serif;
  --font-normal: "Figtree", sans-serif;
}

/* Set Figtree as the default font */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-normal);
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
}

/* Apply Redzone font to specific elements */
h1,
h2 {
  margin: var(--margin) 0;
  font-size: var(--font-size-xlarge);
  font-family: var(--font-normal);
  font-weight: bold;
}

p {
  font-size: var(--font-size-medium);
}

a {
  color: inherit; /* Inherits the color of the parent element */
  text-decoration: none; /* Removes underline */
  cursor: pointer; /* Ensures the pointer is shown on hover */
}

a:hover {
  text-decoration: underline; /* Optional: Add underline on hover for clarity */
}

strong {
  font-weight: 600;
}

@media only screen and (min-width: 1280px) {
  .hide-desktop {
    display: none !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover, .text-underline:focus {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/_fonts.scss","webpack://./src/styles/general.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAAA,uBAAA;AAGA,wBAAA;AACA;EACE,qCAAA;EACA,+DAAA;EACA,iBAAA;EACA,kBAAA;ACAF;ADGA;EACE,yCAAA;EACA,+DAAA;EACA,iBAAA;EACA,kBAAA;ACDF;ADIA;EACE,wCAAA;EACA,+DAAA;EACA,iBAAA;EACA,kBAAA;ACFF;ADKA;EACE,6BAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;ACHF;ADMA;EACE,sCAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;ACJF;ADOA;EACE,2CAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;ACLF;ADQA;EACE,mCAAA;EACA,+DAAA;EACA,kBAAA;ACNF;ADSA;EACE,mCAAA;EACA,+DAAA;EACA,kBAAA;ACPF;ADUA;EACE,kCAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACRF;ADWA;EACE,kCAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;ACTF;ADYA;EACE,kCAAA;EACA,gEAAA;EACA,gBAAA;EACA,kBAAA;ACVF;AA9DA;EACE,wBAAA;EACA,0BAAA;EACA,wBAAA;EACA,qBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;EACA,wBAAA;EACA,yBAAA;EACA,uBAAA;EACA,0BAAA;EACA,cAAA;EACA,aAAA;EACA,iDAAA;EACA,oCAAA;AAgEF;;AA7DA,oCAAA;AACA;EACE,SAAA;EACA,UAAA;EACA,+BAAA;EACA,yCAAA;EACA,wBAAA;EACA,iBAAA;AAgEF;;AA7DA,4CAAA;AACA;;EAEE,uBAAA;EACA,kCAAA;EACA,+BAAA;EACA,iBAAA;AAgEF;;AA7DA;EACE,kCAAA;AAgEF;;AA7DA;EACE,cAAA,EAAA,6CAAA;EACA,qBAAA,EAAA,sBAAA;EACA,eAAA,EAAA,0CAAA;AAgEF;;AA7DA;EACE,0BAAA,EAAA,iDAAA;AAgEF;;AA7DA;EACE,gBAAA;AAgEF;;AC5FU;ED+BV;IAEI,wBAAA;EAgEF;AACF;;AA7DA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AAgEF;;AA7DA;EACE,0BAAA;AAgEF;AA9DE;EAEE,qBAAA;AA+DJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
