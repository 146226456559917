// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jwH_GEfU38Ss4qgPGSJ1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a146y4a3qNon6vXLtnc1 {
  position: relative;
  top: -65px;
}`, "",{"version":3,"sources":["webpack://./src/components/Match/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": `jwH_GEfU38Ss4qgPGSJ1`,
	"bottomBox": `a146y4a3qNon6vXLtnc1`
};
export default ___CSS_LOADER_EXPORT___;
