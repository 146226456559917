// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/youtube.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jwH_GEfU38Ss4qgPGSJ1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a146y4a3qNon6vXLtnc1 {
  position: relative;
  top: -65px;
}

.XXHhX3rMyhEfbj27J_2w {
  cursor: pointer;
  position: relative;
}
.XXHhX3rMyhEfbj27J_2w::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  width: 20%;
  height: 20%;
  transition: opacity 0.4s;
  opacity: 0;
}
.XXHhX3rMyhEfbj27J_2w:hover::before, .XXHhX3rMyhEfbj27J_2w:focus::before {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Match/styles.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,UAAA;AAFF;;AAKA;EACE,eAAA;EACA,kBAAA;AAFF;AAIE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAEE,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,wBAAA;EAEF,UAAA;EACA,WAAA;EACA,wBAAA;EACA,UAAA;AAJJ;AASI;EACE,UAAA;AAPN","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": `jwH_GEfU38Ss4qgPGSJ1`,
	"bottomBox": `a146y4a3qNon6vXLtnc1`,
	"hasVideo": `XXHhX3rMyhEfbj27J_2w`
};
export default ___CSS_LOADER_EXPORT___;
