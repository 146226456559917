// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HmH_D98F99PdaXT9N7Oq {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-template-rows: auto auto;
  text-transform: uppercase;
  font-size: 5cqi;
  font-weight: 300;
}

.ssGXm_mgOXzakSfXM485 {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #000;
  padding: 3cqi;
  border-bottom: 4cqi solid #fff;
}

.bOqy345SjbfkBgs4dnSc {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 5cqi 3cqi 3cqi;
  background-color: var(--player-bg-top-back, var(--player-bg-top, #000));
  font-size: 90%;
}

.jy6zevKENvgob8kjVwQl {
  width: 100%;
}

.cGtiUhCxlhnyYpVUZk9O {
  line-height: 0;
  width: 100%;
  font-weight: 600;
  font-size: 125%;
}

.Yyg36WUnBdvW7T8OyQJX {
  align-self: flex-end;
  font-family: "Superline", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 550%;
  font-weight: 400;
}

.dkmcyI4NBKTYlDxK29zz {
  display: flex;
  flex-wrap: wrap;
  padding: 2cqi 5cqi;
  box-sizing: border-box;
  min-height: 48cqi;
  background-color: #000;
  text-transform: none;
}
.dkmcyI4NBKTYlDxK29zz dt {
  flex-basis: 50%;
}
.dkmcyI4NBKTYlDxK29zz dd {
  flex-basis: 50%;
  font-style: italic;
  font-weight: 700;
  text-align: right;
}
.dkmcyI4NBKTYlDxK29zz dt,
.dkmcyI4NBKTYlDxK29zz dd {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 1.8cqi 0;
}
.dkmcyI4NBKTYlDxK29zz dt:last-of-type,
.dkmcyI4NBKTYlDxK29zz dd:last-of-type {
  border-bottom: none;
}

.jy6zevKENvgob8kjVwQl {
  position: relative;
}
.jy6zevKENvgob8kjVwQl::after {
  content: "";
  position: absolute;
  left: auto;
  top: 5%;
  right: 5%;
  width: 25%;
  height: 25%;
  filter: none;
  mask-position: 100% 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/Player/PlayerStats.module.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gCAAA;EACA,6BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,uEAAA;EACA,cAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,oBAAA;ECjCA,oCAAA;EACA,sBAAA;EDkCA,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;;EAEE,iDAAA;EACA,iBAAA;AAFJ;AAII;;EACE,mBAAA;AADN;;AAMA;EACE,kBAAA;AAHF;AAKE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AAHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HmH_D98F99PdaXT9N7Oq`,
	"top": `ssGXm_mgOXzakSfXM485`,
	"left": `bOqy345SjbfkBgs4dnSc`,
	"race": `jy6zevKENvgob8kjVwQl`,
	"mmrTitle": `cGtiUhCxlhnyYpVUZk9O`,
	"mmr": `Yyg36WUnBdvW7T8OyQJX`,
	"list": `dkmcyI4NBKTYlDxK29zz`
};
export default ___CSS_LOADER_EXPORT___;
