import clsx from 'clsx';

import Footer from '../../components/Footer';
import LinkList from '../../components/LinkList';
import MatchListPhases from '../../components/MatchListPhases';
import matchList from '../../data/s5/rookie/playOff.json';
import { Routes } from '../../routes';
import generalStyles from '../../styles/page.module.scss';
import { MatchType } from '../../types/match';
import { parseMatches } from '../../utils';

import { players } from './players';
import styles from './styles.module.scss';

const matches: Record<string, MatchType[]> = Object.fromEntries(
  Object.keys(matchList).map((phaseTitle) => [
    phaseTitle,
    parseMatches(matchList[phaseTitle as keyof typeof matchList], players),
  ]),
);

export const RookieTourPlayoff = () => {
  return (
    <main
      className={clsx(
        generalStyles.page,
        generalStyles.czskLeagueText,
        generalStyles.czskLeagueTextFixed,
        styles.greenColors,
      )}
    >
      <div className={styles.content}>
        <header className={generalStyles.pageSectionTitle}>Rookie tour</header>
        <div className={styles.container}>
          <h1 className={generalStyles.title}>
            Pla<span className={generalStyles.emptyChar}></span>y-off
          </h1>
          <MatchListPhases matches={matches} />
        </div>
      </div>

      <LinkList
        items={[
          { name: 'Skupina A', to: Routes.S5_ROOKIE_TOUR_GROUP_A.path },
          { name: 'Skupina B', to: Routes.S5_ROOKIE_TOUR_GROUP_B.path },
        ]}
      />
      <Footer />
    </main>
  );
};
