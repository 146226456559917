// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uvZHqdYDiSVjpceyCHqz {
  overflow: hidden;
}

.HoG_AoytwAx8FZucKMLf {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2rem;
  outline: none;
  box-sizing: border-box;
  width: 75%;
}
@media screen and (max-width: 768px) {
  .HoG_AoytwAx8FZucKMLf {
    padding: 20px;
    border-radius: 1.5rem;
    width: 97%;
  }
}

.N5Uw_eHt8KhbiA74KFnH {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;AACF;AACE;EARF;IASI,aAAA;IACA,qBAAA;IACA,UAAA;EAEF;AACF;;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `uvZHqdYDiSVjpceyCHqz`,
	"modal": `HoG_AoytwAx8FZucKMLf`,
	"overlay": `N5Uw_eHt8KhbiA74KFnH`
};
export default ___CSS_LOADER_EXPORT___;
