// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DHCyi_VlW8ixFro9MgkL {
  display: flex;
}
@media only screen and (max-width: 1279.9px) {
  .DHCyi_VlW8ixFro9MgkL {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1280px) {
  .ZknNFPCd3RXlNwHGGemh {
    flex: 1;
    margin-left: 120px;
    width: calc(100% - 120px);
    box-sizing: border-box;
  }
}

.AjFDth7q5cUKc89CVdLO {
  --bg: #000;
  background-color: var(--bg);
  color: var(--text-color);
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 1280px) {
  .AjFDth7q5cUKc89CVdLO {
    width: 120px;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    height: 100%; /* Full height of the viewport */
  }
  .AjFDth7q5cUKc89CVdLO::-webkit-scrollbar {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/conf/_dimensions.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;AAHF;AC2CY;EDzCZ;IAII,sBAAA;EAFF;AACF;;ACsBU;EDjBV;IAEI,OAAA;IACA,kBEdkB;IFelB,yBAAA;IACA,sBAAA;EAFF;AACF;;AAKA;EACE,UAAA;EACA,2BAAA;EACA,wBAAA;EACA,kBAAA;EACA,UAAA;AAFF;ACKU;EDRV;IAQI,YE5BkB;IF6BlB,eAAA;IACA,gBAAA;IACA,MAAA;IACA,OAAA;IACA,YAAA,EAAA,gCAAA;EADF;EAGE;IACE,aAAA;EADJ;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DHCyi_VlW8ixFro9MgkL`,
	"content": `ZknNFPCd3RXlNwHGGemh`,
	"nav": `AjFDth7q5cUKc89CVdLO`
};
export default ___CSS_LOADER_EXPORT___;
