import clsx from 'clsx';
import { CSSProperties, FC, MouseEventHandler, useState } from 'react';

import { TeamSubMatchType } from '../../../types/match';
import { TeamType } from '../../../types/teams';
import { useVideoModal } from '../../../hooks/useVideoModal';
import { ShowScore } from '../constants';

import styles from './Match.module.scss';

type MatchProps = {
  team1: TeamType;
  score1?: number;
  team2: TeamType;
  score2?: number;
  description?: string[];
  matches?: TeamSubMatchType[];
  expandable?: boolean;
  fullName?: boolean;
  showScore?: ShowScore;
  videoId?: string;
  className?: string;
};

export const Match: FC<MatchProps> = ({
  team1,
  score1,
  team2,
  score2,
  description,
  matches,
  expandable,
  fullName,
  showScore,
  videoId,
  className,
}) => {
  const showVideo = useVideoModal();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleShowVideo: (videoId: string) => MouseEventHandler = (videoId) => (e) => {
    e.stopPropagation();
    showVideo(videoId);
  };

  return (
    <>
      <div
        className={clsx(styles.match, styles.matchMain, className, {
          [styles.expandable]: expandable,
          [styles.opened]: open,
        })}
        role={expandable ? 'button' : undefined}
        onClick={expandable ? handleToggle : undefined}
      >
        <span className={clsx(styles.team, styles.alignRight)}>
          <span className={styles.teamLogo}>
            <img src={team1.imageStandalone ?? team1.image} className={styles.teamLogoImg} />
          </span>
          {fullName ? (
            <>
              <span className={styles.fullName}>{team1.name}</span>
              <span className={styles.shortName}>{team1.shortcut}</span>
            </>
          ) : (
            team1.shortcut
          )}
        </span>
        <span className={clsx(styles.score, styles.alignCenter)}>
          {showScore === ShowScore.All && `${score1 ?? 0} : ${score2 ?? 0}`}
          {showScore === ShowScore.Known && (score1 || score2 ? `${score1 ?? 0} : ${score2 ?? 0}` : 'vs')}
          {showScore === ShowScore.None && 'vs'}
        </span>
        <span className={styles.team}>
          <span className={styles.teamLogo}>
            <img src={team2.imageStandalone ?? team2.image} className={styles.teamLogoImg} />
          </span>
          {fullName ? (
            <>
              <span className={styles.fullName}>{team2.name}</span>
              <span className={styles.shortName}>{team2.shortcut}</span>
            </>
          ) : (
            team2.shortcut
          )}
        </span>
        {videoId && (
          <div className={styles.video}>
            <button className={styles.videoButton} onClick={handleShowVideo(videoId)}>
              <span className={styles.videoIcon}>
                <span className="visually-hidden">Zobrazit záznam</span>
              </span>
            </button>
          </div>
        )}
      </div>
      {open && (
        <div
          className={styles.matches}
          style={{ '--team1-logo': `url(${team1.image})`, '--team2-logo': `url(${team2.image})` } as CSSProperties}
        >
          {description && (
            <ul className={styles.rules}>
              {description.map((descriptionLine, index) => (
                <li key={`${team1.shortcut}${team2.shortcut}${index}`}>{descriptionLine}</li>
              ))}
            </ul>
          )}
          <ul className={styles.matchList}>
            {matches?.map(({ players1, players2, score1, score2, description }, index) => {
              const score1Total = score1?.reduce<number>((acc, score) => acc + score, 0) ?? 0;
              const score2Total = score2?.reduce<number>((acc, score) => acc + score, 0) ?? 0;
              return (
                <li
                  key={index}
                  className={clsx({
                    [styles.separator]: index,
                  })}
                >
                  {description && <p className={styles.description}>{description}</p>}
                  <div className={styles.match}>
                    <span
                      className={clsx(styles.alignRight, { [styles.loss]: showScore && score1Total < score2Total })}
                    >
                      {players1.length > 1 ? (
                        <ul className={styles.players}>
                          {players1.map(({ nick, race }) => (
                            <li
                              key={nick}
                              className={clsx(
                                styles.player,
                                styles.playerRight,
                                styles.playerRace,
                                styles.playerRaceRight,
                                ...(race?.map((race, i) => styles[`${race}${i ? `-${i}` : ''}`]) ?? []),
                              )}
                            >
                              <span className={styles.nick}>{nick}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span
                          className={clsx(
                            styles.player,
                            styles.playerRight,
                            styles.playerRace,
                            styles.playerRaceRight,
                            ...(players1[0].race?.map((race, i) => styles[`${race}${i ? `-${i}` : ''}`]) ?? []),
                          )}
                        >
                          <span className={styles.nick}>{players1[0].nick}</span>
                        </span>
                      )}
                    </span>
                    <span className={styles.alignCenter}>{showScore ? `${score1Total} : ${score2Total}` : 'vs'}</span>
                    <span className={clsx(styles.players, { [styles.loss]: showScore && score1Total > score2Total })}>
                      {players2.length > 1 ? (
                        <ul className={styles.players}>
                          {players2.map(({ nick, race }) => {
                            return (
                              <li
                                key={nick}
                                className={clsx(
                                  styles.player,
                                  styles.playerRace,
                                  ...(race?.map((race, i) => styles[`${race}${i ? `-${i}` : ''}`]) ?? []),
                                )}
                              >
                                <span className={styles.nick}>{nick}</span>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <span
                          className={clsx(
                            styles.player,
                            styles.playerRace,
                            ...(players2[0].race?.map((race, i) => styles[`${race}${i ? `-${i}` : ''}`]) ?? []),
                          )}
                        >
                          <span className={styles.nick}>{players2[0].nick}</span>
                        </span>
                      )}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
