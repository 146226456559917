import { createContext, ReactNode, useContext, useState } from 'react';
import Modal from 'react-modal';

import styles from './Modal.module.scss';

type OpenModalProps = {
  content: ReactNode;
};

type ModalContextProps = {
  isOpen: boolean;
  open: (_: OpenModalProps) => void;
  close: () => void;
};

const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  open: () => {},
  close: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>();

  const open = ({ content }: OpenModalProps) => {
    document.body.classList.add(styles.body);
    setIsOpen(true);
    setContent(content);
  };
  const close = () => {
    document.body.classList.remove(styles.body);
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isOpen, open, close }}>
      {children}
      <Modal isOpen={isOpen} onRequestClose={close} className={styles.modal} overlayClassName={styles.overlay}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
