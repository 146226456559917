// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ISDrgh04xulzcpbSkZVX {
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
  background-image: radial-gradient(ellipse at top, var(--button-link-bg-color1), var(--button-link-bg-color2));
  border: 1px solid transparent;
  text-transform: uppercase;
  font-family: var(--font-cool);
  font-size: 2.2rem;
}
@media only screen and (min-width: 1280px) {
  .ISDrgh04xulzcpbSkZVX {
    opacity: 0.9;
    transition: opacity 0.4s, border-color 0.4s;
    font-size: 3rem;
    max-width: 300px;
  }
}
.ISDrgh04xulzcpbSkZVX:hover, .ISDrgh04xulzcpbSkZVX:focus {
  text-decoration: none;
  border-color: var(--button-link-border-color);
}
@media only screen and (min-width: 1280px) {
  .ISDrgh04xulzcpbSkZVX:hover, .ISDrgh04xulzcpbSkZVX:focus {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/button.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAIA;EACE,2BAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EAEA,kGAAA;EACA,6GAAA;EACA,6BAAA;EACA,yBAAA;EACA,6BAAA;EACA,iBAAA;AAJF;ACiBU;EDzBV;IAeI,YAAA;IACA,2CACE;IAEF,eAAA;IACA,gBAAA;EALF;AACF;AAOE;EAEE,qBAAA;EACA,6CAAA;AANJ;ACKU;EDFR;IAMI,UAAA;EALJ;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ISDrgh04xulzcpbSkZVX`
};
export default ___CSS_LOADER_EXPORT___;
