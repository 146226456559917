// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1pBndkSNwKmdqGEiZF2 {
  font-family: var(--font-cool);
  font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Player/PlayerResult.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,eAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result": `D1pBndkSNwKmdqGEiZF2`
};
export default ___CSS_LOADER_EXPORT___;
