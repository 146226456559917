// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zw3UyUTHW6XxRAgnOIfw {
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1600px;
}
@media only screen and (min-width: 768px) {
  .Zw3UyUTHW6XxRAgnOIfw {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.125rem;
  }
}
@media only screen and (min-width: 1280px) {
  .Zw3UyUTHW6XxRAgnOIfw {
    margin: 6rem auto 10rem;
  }
}

.TC1TexTXp6fms6LOHiGE {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  text-align: center;
  flex: 1 0 auto;
  width: 240px;
  max-width: 240px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .TC1TexTXp6fms6LOHiGE {
    flex-basis: calc(33.3333333333% - 1.125rem);
    width: auto;
  }
}
@media only screen and (min-width: 1280px) {
  .TC1TexTXp6fms6LOHiGE {
    flex-basis: calc(16.6666666667% - 1.125rem);
  }
}

.VU8aRka4evjuBODqMCJP:hover, .VU8aRka4evjuBODqMCJP:focus {
  text-decoration: none;
}

.ie7APZOs00QhL5OQD5O0 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--team-card-border-color, #8fcfce);
  border-radius: 0.6rem;
  background-image: linear-gradient(var(--team-card-gradient-1, #05141b), var(--team-card-gradient-2, #246d80));
  flex-basis: calc(16.6666666667% - 18px);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  min-height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  font-weight: 600;
}
@media only screen and (min-width: 768px) {
  .ie7APZOs00QhL5OQD5O0 {
    transition: transform 0.4s;
    will-change: transform;
  }
}
@media only screen and (min-width: 768px) {
  .ie7APZOs00QhL5OQD5O0:hover, .ie7APZOs00QhL5OQD5O0:focus {
    transform: scale(1.03);
  }
}

.dKI2kZpUF8WFwSpFAmr9 {
  max-height: 150px;
  margin-bottom: 0.5rem;
}

.RWijlXPcfEHNgqi4W1EE {
  font-size: 1.5rem;
}

.mB7FZ9xN3a9hBVaEIxg3 {
  font-size: 1.2rem;
}

.G1Z8mCn0HscRZFBIfuaH {
  background-color: #d8d6d7;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  font-size: 1.15rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  text-align: center;
  color: #000;
  margin-top: 1rem;
}

.DnjJjF2amhk6FYrNYbaz {
  font-weight: 400;
  font-size: 90%;
}

.AKpdeXGvLZ7S6rWwH6D2 {
  font-weight: 700;
  font-size: 120%;
  margin-bottom: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/Leaderboard.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAQA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AAPF;ACuBU;EDrBV;IAQI,mBAAA;IACA,eAAA;IACA,aAZO;EAMT;AACF;ACgBU;EDrBV;IAcI,uBAAA;EALF;AACF;;AAQA;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;AALF;ACAU;EDHV;IAWI,2CAAA;IACA,WAAA;EAJF;AACF;ACNU;EDHV;IAgBI,2CAAA;EAHF;AACF;;AAOE;EAEE,qBAAA;AALJ;;AASA;EAEI,iBAAA;EACA,mBAAA;EACA,oDAAA;EACA,qBAAA;EAEF,6GAAA;EAIA,uCAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;AAXF;AChCU;EDwBV;IAsBI,0BAAA;IACA,sBAAA;EAVF;AACF;ACtCU;EDkDR;IAGI,sBAAA;EAXJ;AACF;;AAeA;EACE,iBAAA;EACA,qBAAA;AAZF;;AAeA;EACE,iBAAA;AAZF;;AAeA;EACE,iBAAA;AAZF;;AAeA;EACE,yBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AAZF;;AAeA;EACE,gBAAA;EACA,cAAA;AAZF;;AAeA;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;AAZF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Zw3UyUTHW6XxRAgnOIfw`,
	"item": `TC1TexTXp6fms6LOHiGE`,
	"link": `VU8aRka4evjuBODqMCJP`,
	"card": `ie7APZOs00QhL5OQD5O0`,
	"logo": `dKI2kZpUF8WFwSpFAmr9`,
	"name": `RWijlXPcfEHNgqi4W1EE`,
	"ranking": `mB7FZ9xN3a9hBVaEIxg3`,
	"stats": `G1Z8mCn0HscRZFBIfuaH`,
	"statsItem": `DnjJjF2amhk6FYrNYbaz`,
	"points": `AKpdeXGvLZ7S6rWwH6D2`
};
export default ___CSS_LOADER_EXPORT___;
