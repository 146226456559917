import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { StatsProvider } from './StatsContext';

export const Layout: FC = () => {
  return (
    <StatsProvider>
      <Outlet />
    </StatsProvider>
  );
};
