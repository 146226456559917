import clsx from 'clsx';
import { FC } from 'react';

import { useVideoModal } from '../../hooks/useVideoModal';
import { MatchType } from '../../types/match';

import MatchBox from './MatchBox';
import styles from './styles.module.scss';

type MatchProps = MatchType;

const Match: FC<MatchProps> = ({ player1, score1, player2, score2, videoId: videoUrl }) => {
  const showVideoModal = useVideoModal();

  return (
    <div
      className={clsx(styles.group, {
        [styles.hasVideo]: videoUrl,
      })}
      {...(videoUrl
        ? {
            role: 'button',
            title: 'Zobrazit záznam',
            onClick: () => showVideoModal(videoUrl),
          }
        : {})}
    >
      <MatchBox player={player1} score={score1} position="top" isWinner={Number(score1) > Number(score2)} />
      <MatchBox
        player={player2}
        score={score2}
        position="bottom"
        isWinner={Number(score2) > Number(score1)}
        className={styles.bottomBox}
      />
    </div>
  );
};

export default Match;
