// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QWcsfLSbHAzPxQnyMFHo {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 7rem auto 10rem;
  align-items: center;
  max-width: 70vw;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .QWcsfLSbHAzPxQnyMFHo {
    flex-direction: row;
    justify-content: center;
    max-width: 90vw;
  }
}
@media only screen and (min-width: 1280px) {
  .QWcsfLSbHAzPxQnyMFHo {
    max-width: 1000px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LinkList.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AAFF;ACoBU;ED1BV;IAWI,mBAAA;IACA,uBAAA;IACA,eAAA;EADF;AACF;ACaU;ED1BV;IAiBI,iBAAA;EAAF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWrapper": `QWcsfLSbHAzPxQnyMFHo`
};
export default ___CSS_LOADER_EXPORT___;
