// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UfB_6EZK_JzWLjyZjaif {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
}
@media only screen and (min-width: 1280px) {
  .UfB_6EZK_JzWLjyZjaif {
    padding: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AAFF;ACuBU;ED1BV;IAQI,aAAA;EADF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `UfB_6EZK_JzWLjyZjaif`
};
export default ___CSS_LOADER_EXPORT___;
