import { players as playersGeneral } from './players';
import { MatchType } from './types/match';
import { PlayerType, Race, Stats } from './types/players';

export const getRoundRobinMatches = (players: PlayerType[]): MatchType[] => {
  const matches: MatchType[] = [];
  for (let i = 0; i < players.length; i++) {
    for (let j = i + 1; j < players.length; j++) {
      matches.push({ player1: players[i], player2: players[j] });
    }
  }
  return matches;
};

export const parseMatches = (json: unknown[], players: Record<string, PlayerType>) => {
  return json.map<MatchType>((item) => {
    const { player1, player2, score1, score2, videoId } = item as {
      player1: string;
      player2: string;
      score1?: number;
      score2?: number;
      videoId?: string;
    };
    return {
      player1: typeof player1 === 'string' ? (players[player1] ?? playersGeneral.unknown) : player1,
      player2: typeof player2 === 'string' ? (players[player2] ?? playersGeneral.unknown) : player2,
      score1,
      score2,
      videoId,
    };
  });
};

export const parseStats = (json: unknown) => {
  if (typeof json !== 'object' || json === null) {
    return [];
  }
  return Object.keys(json).map<Stats>((race) => {
    const [wins, losses] = json[race as keyof typeof json] as [number, number];
    return {
      vs: race as Race,
      wins,
      losses,
    };
  });
};

export const inflex = (value: number, [one, few, many]: string[]) => {
  if (value === 0 || value > 4) {
    return many;
  }
  if (value === 1) {
    return one;
  }
  return few;
};
