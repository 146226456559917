import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Layout from './Layout';
import { Routes as Pages } from './routes';
import ScrollToTop from './components/ScrollToTop';
import { ModalProvider } from './components/Modal';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ModalProvider>
        <Layout>
          <Routes>
            {Object.values(Pages).map((route) => {
              const { path, component } = route;
              return (
                <Route key={path} path={path} element={component}>
                  {Object.values(('routes' in route && route.routes) ?? {}).map(({ path, component }) => (
                    <Route key={path} path={path} element={component} />
                  ))}
                </Route>
              );
            })}
            <Route index element={<Navigate to={Pages.TL25.routes.OVERVIEW.path} replace />} />
          </Routes>
        </Layout>
      </ModalProvider>
    </Router>
  );
};

export default App;
