import { FC } from 'react';

import Socials from './Socials';
import styles from './Footer.module.scss';

const Footer: FC = () => {
  return (
    <aside className={styles.base}>
      <Socials />
      <p>&copy; {new Date().getFullYear()} CZSK Starcraft</p>
    </aside>
  );
};

export default Footer;
