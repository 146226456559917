import { FC, ReactNode } from 'react';

import matchStyles from '../styles/match.module.scss';
import pageStyles from '../styles/page.module.scss';
import { GroupFormat } from '../tournament';
import { MatchType } from '../types/match';
import { PlayerDetailType } from '../types/players';
import { getRoundRobinMatches } from '../utils';

import styles from './Group.module.scss';
import Match from './Match';
import MatchListPhases from './MatchListPhases';
import Player from './Player';

type GroupProps = {
  players: PlayerDetailType[];
  title: string;
  children?: ReactNode;
  topText?: ReactNode;
  preMatchesContent?: ReactNode;
  playersHeadingClassName?: string;
} & (
  | {
      groupFormat: GroupFormat.RoundRobin;
      matches?: MatchType[];
    }
  | {
      groupFormat: GroupFormat.Bracket;
      matches?: Record<string, MatchType[]>;
    }
  | {
      groupFormat?: never;
      matches?: never;
    }
);

export const Group: FC<GroupProps> = ({
  players,
  title,
  children,
  groupFormat,
  matches,
  topText,
  preMatchesContent,
  playersHeadingClassName = 'visually-hidden',
}) => {
  const matchList = matches || (groupFormat === GroupFormat.RoundRobin ? getRoundRobinMatches(players) : []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <h1 className={pageStyles.title}>{title}</h1>

        <div className={styles.topText}>{topText}</div>

        <section>
          <h2 className={playersHeadingClassName}>Hráči</h2>

          <ul className={styles.playerList}>
            {players.map((player, i) => (
              <li key={i} className={styles.player}>
                <Player {...player} />
              </li>
            ))}
          </ul>
        </section>
      </div>

      {preMatchesContent}

      {matchList && (!Array.isArray(matchList) || matchList.length > 0) && (
        <>
          {groupFormat === GroupFormat.RoundRobin && (
            <section>
              <h2 className="visually-hidden">Zápasy</h2>
              <ul className={matchStyles.matchList}>
                {(matchList as MatchType[]).map(({ player1, score1, player2, score2 }, i) => (
                  <li key={i}>
                    <Match player1={player1} score1={score1} player2={player2} score2={score2} />
                  </li>
                ))}
              </ul>
            </section>
          )}
          {groupFormat === GroupFormat.Bracket && (
            <MatchListPhases matches={matchList as Record<string, MatchType[]>} className={styles.matchList} />
          )}
        </>
      )}

      {children}
    </div>
  );
};
