import clsx from 'clsx';
import { partition } from 'lodash';
import { CSSProperties, useMemo } from 'react';

import Footer from '../../components/Footer';
import { inflex } from '../../utils';

import Player from './components/Player';
import { useStats } from './StatsContext';
import teamDetailStyles from './TeamDetail.module.scss';
import { WeekMatches } from './components/WeekMatches';
import styles from './styles.module.scss';
import { teams } from './teams';
import { getPlayersStats } from './utils';
import { ShowScore } from './constants';

// const matches: Record<string, MatchType[]> = Object.fromEntries(
//   Object.keys(matchList).map((phaseTitle) => [
//     phaseTitle,
//     parseMatches(matchList[phaseTitle as keyof typeof matchList], players),
//   ]),
// );

export const TeamDetail = ({ name }: { name: string }) => {
  const team = teams[name];
  const { stats, parsedWeeks } = useStats();
  const teamStats = stats[team.name];

  const matches = useMemo(() => {
    const now = new Date();
    return Object.keys(parsedWeeks).flatMap((title) => ({
      title,
      showScore: (parsedWeeks[title].end ?? parsedWeeks[title].start) <= now ? ShowScore.All : ShowScore.None,
      start: parsedWeeks[title].start,
      end: parsedWeeks[title].end,
      description: parsedWeeks[title].description,
      matches: parsedWeeks[title].matches
        .filter((match) => match.team1.name === team.name || match.team2.name === team.name)
        .map((match) => {
          if (match.team2.name === team.name) {
            return {
              ...match,
              team1: match.team2,
              team2: match.team1,
              score1: match.score2,
              score2: match.score1,
              matches: match.matches?.map((match) => ({
                players1: match.players2,
                players2: match.players1,
                score1: match.score2,
                score2: match.score1,
              })),
            };
          }
          return match;
        }),
    }));
  }, [parsedWeeks, team.name]);

  const playerStats = useMemo(
    () =>
      getPlayersStats(
        team.players,
        matches.flatMap((week) => week.matches),
      ),
    [matches, team.players],
  );

  if (!team) {
    return 404;
  }

  const [[captain], players] = partition(team.players, 'isCaptain');

  return (
    <main
      className={clsx(styles.bg, teamDetailStyles.page)}
      style={
        {
          '--team-logo': `url(${team.image})`,
          ...(team.playerCard
            ? {
                '--player-bg-top': team.playerCard.bgGradientTop,
                '--player-bg-bottom': team.playerCard.bgGradientBottom,
                ...(team.playerCard.bgGradientDirection
                  ? {
                      '--player-bg-direction': team.playerCard.bgGradientDirection,
                    }
                  : {}),
                ...(team.playerCard.bgGradientTopBack
                  ? {
                      '--player-bg-top-back': team.playerCard.bgGradientTopBack,
                    }
                  : {}),
                ...(team.playerCard.bgGradientBottomBack
                  ? {
                      '--player-bg-bottom-back': team.playerCard.bgGradientBottomBack,
                    }
                  : {}),
                ...(team.playerCard.bgInfoStripe
                  ? {
                      '--player-bg-info-stripe': team.playerCard.bgInfoStripe,
                    }
                  : {}),
              }
            : {}),
        } as CSSProperties
      }
    >
      <div className={styles.content}>
        <div className={styles.content}>
          <div className={teamDetailStyles.container}>
            <div className={teamDetailStyles.info}>
              <h1 className={clsx(teamDetailStyles.name, teamDetailStyles.uppercase)}>{team.name}</h1>
              <img src={team.imageStandalone ?? team.image} className={teamDetailStyles.logo} />
              <span className={teamDetailStyles.shortcut}>{team.shortcut}</span>
              <div className={teamDetailStyles.ranking}>
                #{teamStats.rank} / {teamStats.points}&nbsp;{inflex(teamStats.points, ['bod', 'body', 'bodů'])}
              </div>
              <div className={clsx(teamDetailStyles.captainName, teamDetailStyles.uppercase)}>
                <small>kapitán</small> <strong>{captain.nick}</strong>
              </div>
            </div>
            <div className={teamDetailStyles.captain}>
              <Player
                {...captain}
                mmr={captain.mmr}
                teamName={team.name}
                stats={playerStats[captain.nick]}
                className={teamDetailStyles.playerCard}
                key={captain.nick}
              />
            </div>
            <ul className={teamDetailStyles.playerList}>
              {players.map((player, i) => (
                <li key={`${team.shortcut}${i}`} className={teamDetailStyles.player}>
                  <Player
                    {...player}
                    mmr={player.mmr}
                    teamName={team.name}
                    stats={playerStats[player.nick]}
                    className={teamDetailStyles.playerCard}
                  />
                </li>
              ))}
            </ul>
          </div>
          <h2 className="visually-hidden">Zápasy</h2>
          <ul className={teamDetailStyles.matches}>
            {matches.map((week, index) => (
              <li key={`${team.shortcut}${index}`}>
                <WeekMatches {...week} fullName expandable />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </main>
  );
};
