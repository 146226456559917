import clsx from 'clsx';
import { FC, useState } from 'react';

import person from '../../../../assets/images/tl25/players/picusove.webp';

import { PlayerDetailType } from './types';
import styles from './styles.module.scss';
import PlayerStats from './PlayerStats';

type PlayerProps = PlayerDetailType & { className?: string };

const Player: FC<PlayerProps> = (player) => {
  const { className, image, teamName, nick, race, mmr } = player;

  const [isFlipped, setIsFlipped] = useState(false);

  const flipCard = () => {
    setIsFlipped((prev) => !prev);
  };

  return (
    <div className={clsx(styles.flipper, className)} onClick={flipCard}>
      <div className={clsx(styles.holder, { [styles.flipped]: isFlipped })}>
        <div className={clsx(styles.wrapper, styles.back)}>
          <div className={clsx(styles.inner, styles.innerBack)} data-teamname={teamName}>
            <div className={clsx(styles.content, styles.contentBack)}>
              <div className={clsx(styles.photo, styles.photoBack)}>
                <img src={image ?? person} className={styles.image} />
              </div>
              <div className={styles.stats}>
                <PlayerStats {...player} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={clsx(styles.inner, styles.innerFront, styles.race, styles[race])}>
            <span className="visually-hidden">{race}</span>
            <div className={clsx(styles.content, styles.contentFront)}>
              <div className={clsx(styles.photo, styles.photoFront)}>
                <img src={image ?? person} className={styles.image} />
              </div>
              <div className={styles.info}>
                <span className={styles.nick}>{nick}</span>
                {mmr} MMR
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
