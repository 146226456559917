import photoPerson from '../../assets/images/person.webp';
import photoBab from '../../assets/images/tl25/players/bab.webp';
import photoBrhouse from '../../assets/images/tl25/players/brhouse.webp';
import photoBuckfast from '../../assets/images/tl25/players/buckfast.webp';
import photoBudaak from '../../assets/images/tl25/players/budaak.webp';
import photoBukaj from '../../assets/images/tl25/players/bukaj.webp';
import photoCrow from '../../assets/images/tl25/players/crow.webp';
import photoDante from '../../assets/images/tl25/players/dante.webp';
import photoDera from '../../assets/images/tl25/players/dera.webp';
import photoFutu from '../../assets/images/tl25/players/futu.webp';
import photoGotch from '../../assets/images/tl25/players/gotch.webp';
import photoIzu from '../../assets/images/tl25/players/izu.webp';
import photoLg from '../../assets/images/tl25/players/lg.webp';
import photoMaf from '../../assets/images/tl25/players/maf.webp';
import photoMasicko from '../../assets/images/tl25/players/masicko.webp';
import photoMdg from '../../assets/images/tl25/players/mdg.webp';
import photoMeinhold from '../../assets/images/tl25/players/meinhold.webp';
import photoMetodman from '../../assets/images/tl25/players/metodman.webp';
import photoMoses from '../../assets/images/tl25/players/moses.webp';
import photoNolimit from '../../assets/images/tl25/players/nolimit.webp';
import photoPhoenka from '../../assets/images/tl25/players/phoenka.webp';
import photoPersonT25 from '../../assets/images/tl25/players/picusove.webp';
import photoPetros from '../../assets/images/tl25/players/petros.webp';
import photoPodes from '../../assets/images/tl25/players/podes.webp';
import photoSatik from '../../assets/images/tl25/players/satik.webp';
import photoShepp from '../../assets/images/tl25/players/shepp.webp';
import photoSid from '../../assets/images/tl25/players/sid.webp';
import photoSkophek from '../../assets/images/tl25/players/skophek.webp';
import photoSmoke from '../../assets/images/tl25/players/smoke.webp';
import photoStriker from '../../assets/images/tl25/players/striker.webp';
import photoSurgen from '../../assets/images/tl25/players/surgen.webp';
import photoSyrax from '../../assets/images/tl25/players/syrax.webp';
import photoTachometr from '../../assets/images/tl25/players/tachometr.webp';
import photoTomcher from '../../assets/images/tl25/players/tomcher.webp';
import photoVaclavson from '../../assets/images/tl25/players/vaclavson.webp';
import photoWallSk from '../../assets/images/tl25/players/wall_sk.webp';
import photoWill from '../../assets/images/tl25/players/will.webp';
import photoWlad from '../../assets/images/tl25/players/wlad.webp';
import logoBlackHorses from '../../assets/images/tl25/teams/black_horses.webp';
import logoBuddyBurger from '../../assets/images/tl25/teams/buddy_burger.webp';
import logoBuddyBurgerBg from '../../assets/images/tl25/teams/buddy_burger_bg.webp';
import logoDivineIntellect from '../../assets/images/tl25/teams/divine_intellect.webp';
import logoLimitedLamas from '../../assets/images/tl25/teams/limited_lamas.webp';
import logoSonsOfVaclavson from '../../assets/images/tl25/teams/sons_of_vaclavson.webp';
import logoTachoStarTeam from '../../assets/images/tl25/teams/tachostarteam.webp';
import mmr from '../../data/tl25/mmr.json';
import { players as playersData } from '../../players';
import { TeamType } from '../../types/teams';

const assignMmr = (teams: Record<string, TeamType>) =>
  Object.fromEntries(
    Object.entries(teams).map(([teamName, team]) => [
      teamName,
      {
        ...team,
        players: Object.fromEntries(
          Object.keys(team.players).map((player) => [
            player,
            {
              ...team.players[player],
              mmr: mmr[player as keyof typeof mmr] ?? undefined,
            },
          ]),
        ),
      },
    ]),
  ) as Record<string, TeamType>;

const assignEmptyPhoto = (teams: Record<string, TeamType>) =>
  Object.fromEntries(
    Object.entries(teams).map(([teamName, team]) => [
      teamName,
      {
        ...team,
        players: Object.fromEntries(
          Object.keys(team.players).map((player) => [
            player,
            {
              ...team.players[player],
              ...(team.players[player].image === photoPerson ? { image: photoPersonT25 } : {}),
            },
          ]),
        ),
      },
    ]),
  ) as Record<string, TeamType>;

export const teams = assignMmr(
  assignEmptyPhoto({
    Vaclavson: {
      name: 'Sons of Vaclav',
      shortcut: 'SoV',
      players: {
        Vaclavson: {
          ...playersData.Vaclavson,
          isCaptain: true,
          image: photoVaclavson,
        },
        meinhold: {
          ...playersData.meinhold,
          image: photoMeinhold,
        },
        MetodMan: {
          ...playersData.MetodMan,
          image: photoMetodman,
        },
        Budaak: {
          ...playersData.Budaak,
          image: photoBudaak,
        },
        Wlad: {
          ...playersData.Wlad,
          image: photoWlad,
        },
        Podes: {
          ...playersData.Podes,
          image: photoPodes,
        },
        Bukajj: {
          ...playersData.Bukajj,
          image: photoBukaj,
        },
      },
      image: logoSonsOfVaclavson,
      route: 'TEAM_DETAIL_VACLAVSON',
      card: {
        borderColor: '#567e82',
        bgGradientTop: '#051b3d',
        bgGradientBottom: '#0d47a1',
      },
      playerCard: {
        bgGradientTop: '#051b3d',
        bgGradientBottom: '#0d47a1',
        bgGradientTopBack: '#0d47a1',
        bgGradientBottomBack: '#051b3d',
      },
    },
    Nolimit: {
      name: 'Limited Lamas',
      shortcut: '2L',
      players: {
        Nolimit: {
          ...playersData.Nolimit,
          isCaptain: true,
          image: photoNolimit,
        },
        LG: {
          ...playersData.LG,
          image: photoLg,
        },
        Crow: {
          ...playersData.Crow,
          image: photoCrow,
        },
        Buckfast: {
          ...playersData.Buckfast,
          image: photoBuckfast,
        },
        m0ses: {
          ...playersData.m0ses,
          image: photoMoses,
        },
        wall_sk: {
          ...playersData.wall_sk,
          image: photoWallSk,
        },
        Milovan: {
          ...playersData.Milovan,
        },
      },
      image: logoLimitedLamas,
      route: 'TEAM_DETAIL_NOLIMIT',
      card: {
        borderColor: '#4b6c6a',
        bgGradientTop: '#0b3840',
        bgGradientBottom: '#1e93a9',
      },
      playerCard: {
        bgGradientTop: '#0b3840',
        bgGradientBottom: '#1e93a9',
        bgGradientTopBack: '#1e93a9',
        bgGradientBottomBack: '#0b3840',
      },
    },
    Tomcher: {
      name: 'Divine Intellect',
      shortcut: 'DI',
      players: {
        Tomcher: {
          ...playersData.Tomcher,
          isCaptain: true,
          image: photoTomcher,
        },
        Syrax: {
          ...playersData.Syrax,
          image: photoSyrax,
        },
        gblcia: {
          ...playersData.gblcia,
        },
        FuTu: {
          ...playersData.FuTu,
          image: photoFutu,
        },
        Malek: {
          ...playersData.Censured,
          nick: 'Malek',
          race: 'Zerg',
        },
        Meduza: {
          ...playersData.Meduza,
          image: photoSmoke,
        },
        MDG: {
          ...playersData.MDG,
          image: photoMdg,
        },
      },
      image: logoDivineIntellect,
      route: 'TEAM_DETAIL_TOMCHER',
      card: {
        borderColor: '#304244',
        bgGradientTop: '#12141d',
        bgGradientBottom: '#2f354d',
      },
      playerCard: {
        bgGradientTop: '#12141d',
        bgGradientBottom: '#2f354d',
        bgGradientTopBack: '#2f354d',
        bgGradientBottomBack: '#12141d',
      },
    },
    BaB: {
      name: 'Black Horses',
      shortcut: 'BH',
      players: {
        BaB: {
          ...playersData.BaB,
          isCaptain: true,
          image: photoBab,
        },
        Petros: {
          ...playersData.Petros,
          image: photoPetros,
        },
        Dante: {
          ...playersData.Dante,
          image: photoDante,
        },
        Phoenka: {
          ...playersData.Phoenka,
          image: photoPhoenka,
        },
        BrHouse: {
          ...playersData.BrHouse,
          image: photoBrhouse,
        },
        Dera: {
          ...playersData.Dera,
          image: photoDera,
        },
        Sid: {
          ...playersData['Sid'],
          image: photoSid,
        },
      },
      route: 'TEAM_DETAIL_BAB',
      image: logoBlackHorses,
      card: {
        borderColor: '#2f4342',
        bgGradientTop: '#430407',
        bgGradientBottom: '#b00b12',
      },
      playerCard: {
        bgGradientTop: '#e20e1a',
        bgGradientBottom: '#580505',
        bgGradientDirection: '110deg',
      },
    },
    Surgen: {
      name: 'Buddy Burger',
      shortcut: 'BuddyB',
      players: {
        Surgen: {
          ...playersData.Surgen,
          isCaptain: true,
          image: photoSurgen,
        },
        izu: {
          ...playersData.izu,
          image: photoIzu,
        },
        will: {
          ...playersData.will,
          image: photoWill,
        },
        sKopheK: {
          ...playersData.sKopheK,
          image: photoSkophek,
        },
        Striker: {
          ...playersData.Striker,
          image: photoStriker,
        },
        MaF: {
          ...playersData.MaF,
          image: photoMaf,
        },
        Tumek: {
          ...playersData.Tumek,
        },
      },
      route: 'TEAM_DETAIL_SURGEN',
      image: logoBuddyBurger,
      imageStandalone: logoBuddyBurgerBg,
      card: {
        borderColor: '#3f544e',
        bgGradientTop: '#584a25',
        bgGradientBottom: '#e8c360',
      },
      playerCard: {
        bgGradientTop: '#584a25',
        bgGradientBottom: '#e8c360',
        bgGradientTopBack: '#b08f3d',
        bgGradientBottomBack: '#5a471d',
      },
    },
    Tachometr: {
      name: 'TachoStarTeam',
      shortcut: 'tSt',
      players: {
        Tachometr: {
          ...playersData.Tachometr,
          isCaptain: true,
          image: photoTachometr,
        },
        Krocan: {
          ...playersData.Krocan,
        },
        Threo: {
          ...playersData.Threo,
        },
        Masicko: {
          ...playersData.Masicko,
          image: photoMasicko,
        },
        Satik: {
          ...playersData.Satik,
          image: photoSatik,
        },
        gotch: {
          ...playersData.gotch,
          image: photoGotch,
        },
        Shepp: {
          ...playersData.Shepp,
          image: photoShepp,
        },
      },
      image: logoTachoStarTeam,
      route: 'TEAM_DETAIL_TACHOMETR',
      card: {
        borderColor: '#292e3c',
        bgGradientTop: '#280542',
        bgGradientBottom: '#6a0dad',
      },
      playerCard: {
        bgGradientTop: '#280542',
        bgGradientBottom: '#6a0dad',
        bgGradientTopBack: '#6a0dad',
        bgGradientBottomBack: '#280542',
      },
    },
  }),
);
