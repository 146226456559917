import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import btnStyles from '../styles/button.module.scss';

import styles from './LinkList.module.scss';

type LinkListItem = {
  to: string;
  name: ReactNode;
  className?: string;
  target?: string;
};

type LinkListProps = {
  items: (LinkListItem | ReactNode)[];
};

const isLinkListItem = (item: LinkListItem | ReactNode): item is LinkListItem => {
  return (item as LinkListItem).to !== undefined;
};

const LinkList: FC<LinkListProps> = ({ items }) => {
  return (
    <div className={styles.linkWrapper}>
      {items.map((item, i) => {
        if (!isLinkListItem(item)) {
          return item;
        }
        const { to, name, className, target } = item;
        return (
          <Link to={to} className={clsx(btnStyles.button, className)} key={i} target={target}>
            {name}
          </Link>
        );
      })}
    </div>
  );
};

export default LinkList;
