// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f6WOHczEDg47qZFAKSqD {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Socials.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `f6WOHczEDg47qZFAKSqD`
};
export default ___CSS_LOADER_EXPORT___;
