import { FC, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import _uniqueId from 'lodash/uniqueId';
import clsx from 'clsx';

import { MenuItem as MenuItemType } from '../../types/menu';

import styles from './styles.module.scss';
import { hasChildWithUrl } from './utils';
import CloseButton from './CloseButton';

type MenuItemProps = MenuItemType & { handleClose: () => void };

const MenuItem: FC<MenuItemProps> = ({ handleClose, ...item }) => {
  const { title, anchor, submenu, url, opened, disabled } = item;
  const checkboxId = `menu-toggle-${_uniqueId()}`;

  const checkboxRef = useRef<HTMLInputElement>(null);
  const currentLocation = useLocation();

  useEffect(() => {
    if (!opened && !hasChildWithUrl(item, currentLocation.pathname)) return;
    if (checkboxRef.current) {
      checkboxRef.current.checked = true;
    }
  }, [currentLocation.pathname, item, opened]);

  return (
    <li key={url || title}>
      {disabled ? (
        <span className={clsx(styles.menuItem, styles.disabled)}>{title}</span>
      ) : submenu ? (
        <>
          <input type="checkbox" id={checkboxId} className={styles.checkbox} ref={checkboxRef} />
          <label className={clsx(styles.menuItem, styles.expandable)} htmlFor={checkboxId}>
            {title}
          </label>
          <div className={styles.menu}>
            <div className={styles.header}>
              <label className={styles.toggle} htmlFor={checkboxId}>
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="butt"
                  strokeLinejoin="round"
                >
                  <path d="M19 12H6M12 5l-7 7 7 7" />
                </svg>
              </label>
              <span className={styles.headerText}>{title}</span>
              <CloseButton />
            </div>
            <ul>
              {submenu.map((item) => (
                <MenuItem key={item.title} {...item} handleClose={handleClose} />
              ))}
            </ul>
          </div>
        </>
      ) : (
        <Link
          to={`${url ?? ''}${anchor ?? ''}`}
          className={clsx(styles.menuItem, { [styles.active]: currentLocation.pathname === url })}
          onClick={handleClose}
        >
          {title}
        </Link>
      )}
    </li>
  );
};

export default MenuItem;
