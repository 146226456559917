import { FC, memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { menuItems } from '../../menu';
import Socials from '../Socials';

import MenuItem from './MenuItem';
import styles from './styles.module.scss';
import CloseButton from './CloseButton';

const Navigation: FC = () => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const navRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    if (!checkboxRef.current) return;
    checkboxRef.current.checked = false;
  };

  const handleNavigateHome = () => {
    if (!navRef.current) return;
    // TODO: use refs
    navRef.current.querySelectorAll('input[type="checkbox"]').forEach((c) => ((c as HTMLInputElement).checked = false));
  };

  return (
    <>
      <div className={styles.nav}>
        <Link to="/" className={styles.logo} onClick={handleNavigateHome}>
          CZSK StarCraft League
        </Link>
        <label className={clsx(styles.toggle, 'hide-desktop')} htmlFor="menu">
          <svg
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="butt"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </label>
      </div>

      <input type="checkbox" id="menu" name="menu" ref={checkboxRef} className={styles.checkbox} />
      <label className={clsx(styles.overlay, 'hide-desktop')} htmlFor="menu"></label>
      <div className={clsx(styles.menu, styles.menuMain)} ref={navRef}>
        <div className={clsx(styles.header, 'hide-desktop')}>
          <span className={styles.headerText}>CZSK StarCraft League</span>
          <CloseButton />
        </div>
        <ul className={styles.linkList}>
          {menuItems.map((item) => (
            <MenuItem key={item.title} {...item} handleClose={handleClose} />
          ))}
        </ul>
        <Socials className={clsx(styles.socials, 'hide-desktop')} />
      </div>
    </>
  );
};

export default memo(Navigation);
