// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xPS_MHkbiX9s2VPUfR6A {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.1rem;
}

.zcgfSGlHVTQtAWtYpEbj {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.wE9JQIY7BfPu6GEdU7QQ {
  text-align: center;
}

.U6VDjQp5phh6a7g8S5FH {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.2rem;
  color: inherit;
  transition: opacity 0.4s;
}
.U6VDjQp5phh6a7g8S5FH:hover {
  opacity: 0.7;
}
.U6VDjQp5phh6a7g8S5FH:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/WeekMatches.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,wBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAIE;EACE,mBAAA;EACA,YAAA;AAFJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `xPS_MHkbiX9s2VPUfR6A`,
	"heading": `zcgfSGlHVTQtAWtYpEbj`,
	"dates": `wE9JQIY7BfPu6GEdU7QQ`,
	"weekButton": `U6VDjQp5phh6a7g8S5FH`
};
export default ___CSS_LOADER_EXPORT___;
