import { FC, Fragment } from 'react';
import clsx from 'clsx';

import styles from './PlayerStats.module.scss';
import card from './styles.module.scss';
import { PlayerDetailType, StatsType } from './types';

const statTitle: Record<StatsType, string> = {
  vsTerran: 'vs T',
  vsProtoss: 'vs P',
  vsZerg: 'vs Z',
  twoVsTwo: '2v2',
  threeVsThree: '3v3',
};

const PlayerStats: FC<PlayerDetailType> = ({ teamName, nick, race, stats, mmr }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <span className={styles.team}>{teamName}</span>
        <span className={card.nick}>{nick}</span>
      </div>
      <div className={clsx(styles.left, styles.race, card.race, card[race])}>
        <span className={styles.race}>{race}</span>
        <span className={styles.mmrTitle}>MMR</span>
        <span className={styles.mmr}>{mmr}</span>
      </div>
      <dl className={styles.list}>
        {Object.keys(stats ?? {}).map((stat, i) => {
          const data = stats[stat as StatsType];
          const percentage = Math.round((data.wins / (data.wins + data.losses)) * 100);
          return (
            <Fragment key={i}>
              <dt className={styles.label}>{statTitle[stat as StatsType]}</dt>
              <dd className={styles.value}>{Number.isNaN(percentage) ? 'N/A' : `${percentage} %`}</dd>
            </Fragment>
          );
        })}
      </dl>
    </div>
  );
};

export default PlayerStats;
