import { FC } from 'react';

import { MatchType } from '../../types/match';

import MatchBox from './MatchBox';
import styles from './styles.module.scss';

type MatchProps = MatchType;

const Match: FC<MatchProps> = ({ player1, score1, player2, score2 }) => {
  return (
    <div className={styles.group}>
      <MatchBox player={player1} score={score1} position="top" isWinner={Number(score1) > Number(score2)} />
      <MatchBox
        player={player2}
        score={score2}
        position="bottom"
        isWinner={Number(score2) > Number(score1)}
        className={styles.bottomBox}
      />
    </div>
  );
};

export default Match;
