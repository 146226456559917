import { FC, PropsWithChildren, useRef } from 'react';

import styles from './Layout.module.scss';
import Navigation from './components/Navigation';
import { useScrollToHash } from './hooks/useScrollToHash';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const appContainer = useRef<HTMLDivElement>(null);
  useScrollToHash();

  return (
    <div ref={appContainer} className={styles.container}>
      <nav className={styles.nav}>
        <Navigation />
      </nav>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
