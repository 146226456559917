import clsx from 'clsx';

import Footer from '../../components/Footer';
import { Group } from '../../components/Group';
import LinkList from '../../components/LinkList';
import playerStyles from '../../components/Player/styles.module.scss';
import QualificationOutcome from '../../components/QualificationOutcome';
import matchList from '../../data/s5/rookie/groupB.json';
import { Routes } from '../../routes';
import generalStyles from '../../styles/page.module.scss';
import { GroupFormat } from '../../tournament';
import { MatchType } from '../../types/match';
import { parseMatches } from '../../utils';

import { players } from './players';
import styles from './styles.module.scss';

const playerList = [
  {
    ...players.Tomcher,
    rank: 1,
    content: <QualificationOutcome text="Play-off" advances={true} />,
  },
  {
    ...players.Budaak,
    rank: 2,
    content: <QualificationOutcome text="Play-off" advances={true} />,
  },
  {
    ...players.Tachometr,
    rank: 3,
    className: playerStyles.defeated,
  },
  {
    ...players.Egg,
    rank: 4,
    className: playerStyles.defeated,
  },
];

const matches: Record<string, MatchType[]> = Object.fromEntries(
  Object.keys(matchList).map((phaseTitle) => [
    phaseTitle,
    parseMatches(matchList[phaseTitle as keyof typeof matchList], players),
  ]),
);

export const RookieTourGroupB = () => {
  return (
    <main
      className={clsx(
        generalStyles.page,
        generalStyles.czskLeagueText,
        generalStyles.czskLeagueTextFixed,
        styles.greenColors,
      )}
    >
      <div className={clsx(styles.content, styles.container)}>
        <header className={generalStyles.pageSectionTitle}>Rookie tour</header>
        <Group
          title="Skupina B"
          players={playerList}
          playersHeadingClassName={clsx(
            generalStyles.subheading,
            generalStyles.subheadingShade,
            styles.subheadingMarginBottom,
            'hide-desktop',
          )}
          groupFormat={GroupFormat.Bracket}
          matches={matches}
          topText={
            <>
              <section className={styles.description}>
                <p>
                  Hráči, kteří se nekvalifikovali do&nbsp;finálového turnaje anebo vypadli v&nbsp;jeho předkole, měli
                  možnost se přihlásit do&nbsp;<strong>Rookie tour</strong>. Neúspěšní kvalifikanti předkola finále jsou
                  nasazeni do&nbsp; play-off, zbytek účastníků bude bojovat o&nbsp;postup do&nbsp;play-off ve&nbsp;dvou
                  skupinách hraných pavoukem s&nbsp;opravou, ze&nbsp;kterých postoupí dva nejlepší.
                </p>
                <h2 className={clsx(generalStyles.subheading, generalStyles.subheadingSmall, styles.subheading)}>
                  Formát skupin
                </h2>
                <p>Dual tournament: První dva zápasy best-of-1, zbytek best-of-3</p>
                <p>
                  Z každé skupiny postupují dva nejlepší hráči do&nbsp;<strong>play-off</strong>.
                </p>
              </section>
              <div className={styles.videoWrapper}>
                <h3 className={clsx(generalStyles.subheading, generalStyles.subheadingShade)}>Záznam</h3>
                <div className={styles.iframeWrapper}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/118HeQLIQu4"
                    title="CZSK StarCraft League - Season 5 - Rookie tour - Skupina B"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    className={styles.video}
                  ></iframe>
                </div>
              </div>
            </>
          }
        />
      </div>

      <LinkList
        items={[
          { name: 'Skupina A', to: Routes.S5_ROOKIE_TOUR_GROUP_A.path },
          {
            name: (
              <>
                Pla<span className={generalStyles.emptyChar}></span>y-off
              </>
            ),
            to: Routes.S5_ROOKIE_TOUR_PLAYOFF.path,
          },
        ]}
      />
      <Footer />
    </main>
  );
};
