import clsx from 'clsx';
import { FC } from 'react';

import { TeamMatchType } from '../../../types/match';
import { ShowScore } from '../constants';

import { MatchList } from './MatchList';
import styles from './WeekMatches.module.scss';

type WeekMatchesProps = {
  title: string;
  start: Date;
  end: Date | null;
  description?: string[];
  matches: TeamMatchType[];
  showScore?: ShowScore;
  expandable?: boolean;
  fullName?: boolean;
  className?: string;
  weekClassName?: string;
  datesClassName?: string;
  matchesClassName?: string;
  matchClassName?: string;
  showDate?: boolean;
  onWeekChange?: (change: number) => void;
  disablePrevious?: boolean;
  disableNext?: boolean;
};

export const WeekMatches: FC<WeekMatchesProps> = ({
  title,
  start,
  end,
  description,
  matches,
  showScore,
  expandable,
  fullName,
  className,
  weekClassName,
  datesClassName,
  matchesClassName,
  matchClassName,
  showDate = true,
  onWeekChange,
  disablePrevious = false,
  disableNext = false,
}) => {
  const thisYear = new Date().getFullYear();
  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={clsx(styles.heading, weekClassName)}>
        {onWeekChange && (
          <button onClick={() => onWeekChange?.(-1)} disabled={disablePrevious} className={styles.weekButton}>
            ←
          </button>
        )}
        {title}
        {onWeekChange && (
          <button onClick={() => onWeekChange?.(1)} disabled={disableNext} className={styles.weekButton}>
            →
          </button>
        )}
      </div>
      {showDate && (
        <div className={clsx(styles.dates, datesClassName)}>
          {start.getFullYear() === thisYear
            ? start.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric' })
            : start.toLocaleDateString('cs-CZ')}{' '}
          {end && (
            <>
              -{' '}
              {end.getFullYear() === thisYear
                ? end.toLocaleDateString('cs-CZ', { day: 'numeric', month: 'numeric' })
                : end.toLocaleDateString('cs-CZ')}
            </>
          )}
        </div>
      )}
      <MatchList
        matches={matches}
        description={description}
        showScore={showScore}
        expandable={expandable}
        fullName={fullName}
        className={matchesClassName}
        matchClassName={matchClassName}
      />
    </div>
  );
};
