import clsx from 'clsx';
import { FC } from 'react';

import styles from './QualificationOutcome.module.scss';

type QualificationOutcomeProps = {
  text: string;
  advances: boolean;
};

const QualificationOutcome: FC<QualificationOutcomeProps> = ({ text, advances: isAdvances }) => {
  return (
    <div className={clsx(styles.base, { [styles.advances]: isAdvances })}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 150" className={styles.arrow}>
        <polygon points="0,10 60,45 120,0 50,150" fill="currentColor" />
      </svg>
      {text}
    </div>
  );
};

export default QualificationOutcome;
