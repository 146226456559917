import clsx from 'clsx';

import { Group } from '../../components/Group';
import generalStyles from '../../styles/page.module.scss';
import { GroupFormat } from '../../tournament';
import { MatchType } from '../../types/match';
import playerStyles from '../../components/Player/styles.module.scss';
import QualificationOutcome from '../../components/QualificationOutcome';
import Footer from '../../components/Footer';

import { players } from './players';
import styles from './styles.module.scss';

const playerList = [
  {
    ...players.Crow,
    rank: 1,
    stats: undefined,
    content: <QualificationOutcome text="Offline finále" advances={true} />,
  },
  {
    ...players.Striker,
    rank: 2,
    stats: undefined,
    content: <QualificationOutcome text="Offline finále" advances={true} />,
  },
  {
    ...players.sKopheK,
    rank: 3,
    stats: undefined,
    className: playerStyles.defeated,
    content: <QualificationOutcome text="Rookie tour" advances={false} />,
  },
  {
    ...players.Wlad,
    rank: 4,
    stats: undefined,
    className: playerStyles.defeated,
    content: <QualificationOutcome text="Rookie tour" advances={false} />,
  },
];

const matchList: Record<string, MatchType[]> = {
  '1. kolo (BO1)': [
    {
      player1: players.Crow,
      player2: players.Wlad,
      score1: 1,
      score2: 0,
    },
    {
      player1: players.sKopheK,
      player2: players.Striker,
      score1: 1,
      score2: 0,
    },
  ],
  'Finále WB (BO3)': [
    {
      player1: players.Crow,
      player2: players.sKopheK,
      score1: 2,
      score2: 0,
    },
  ],
  '1. kolo LB (BO3)': [
    {
      player1: players.Wlad,
      player2: players.Striker,
      score1: 0,
      score2: 2,
    },
  ],
  'Finále LB (BO3)': [
    {
      player1: players.sKopheK,
      player2: players.Striker,
      score1: 0,
      score2: 2,
    },
  ],
} as const;

export const QualificationGroupBlue = () => {
  return (
    <main
      className={clsx(
        generalStyles.page,
        generalStyles.czskLeagueText,
        generalStyles.czskLeagueTextFixed,
        styles.blueColors,
      )}
    >
      <div className={clsx(styles.content, styles.container)}>
        <header className={generalStyles.pageSectionTitle}>Předkolo finále</header>
        <Group
          title="Blue Group"
          players={playerList}
          playersHeadingClassName={clsx(
            generalStyles.subheading,
            generalStyles.subheadingShade,
            styles.subheadingMarginBottom,
            'hide-desktop',
          )}
          groupFormat={GroupFormat.Bracket}
          matches={matchList}
          topText={
            <>
              <section className={styles.description}>
                <p>
                  Hráči, kteří se umístí na 5. - 12. místě po&nbsp;základní části, postoupí do&nbsp;
                  <strong>předkola finále</strong>, kde se podle pořadí rozdělí do&nbsp;dvou skupin.
                </p>
                <h2 className={clsx(generalStyles.subheading, generalStyles.subheadingSmall, styles.subheading)}>
                  Formát skupin
                </h2>
                <p>Dual tournament: První dva zápasy best-of-1, zbytek best-of-3</p>
                <p>
                  Z každé skupiny postupují dva nejlepší hráči do&nbsp;<strong>offline finále</strong> (28.&nbsp;9.
                  Praha, Magenta Experience Center). Hráči, kteří nepostoupí, budou následně hrát{' '}
                  <strong>Rookie League</strong>.
                </p>
              </section>
              <div className={styles.videoWrapper}>
                <h3 className={clsx(generalStyles.subheading, generalStyles.subheadingShade)}>Záznam</h3>
                <div className={styles.iframeWrapper}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/U0r2dLjYVUg"
                    title="CZSK StarCraft League - Season 5 - Finals qualifiers - Blue Group"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    className={styles.video}
                  ></iframe>
                </div>
              </div>
            </>
          }
        />
      </div>
      <Footer />
    </main>
  );
};
