// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/race_terran_simple.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/images/race_zerg.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../assets/images/race_protoss.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../assets/images/race_random.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._37mAUZo6lnKmwXuVUxQ {
  display: grid;
  grid-template-columns: 2fr 0.9fr 2fr;
  column-gap: 1.4rem;
  align-items: center;
  position: relative;
}
@media only screen and (min-width: 768px) {
  ._37mAUZo6lnKmwXuVUxQ {
    grid-template-columns: 2fr 0.5fr 2fr;
  }
}

.Ppye6l9IjEdS7MZ9IUAQ {
  text-align: center;
  font-size: 75%;
  margin-bottom: 0.3rem;
  opacity: 0.5;
}

.AOVGWswwXJacpZdVFHl1 {
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  font-size: 85%;
  line-height: 1.2;
  background: rgba(0, 0, 0, 0.3);
}

.XZv33Nt5h7aLTxJaOXUg {
  min-height: 52px;
  box-sizing: border-box;
  padding: 0.4rem;
  background-color: rgba(70, 80, 89, 0.55);
  font-size: 1.4rem;
  font-weight: 600;
}
@media only screen and (min-width: 768px) {
  .XZv33Nt5h7aLTxJaOXUg {
    font-size: 1.6rem;
  }
}

.hJzDXYJDOfuWf2608dCg {
  cursor: pointer;
}
.hJzDXYJDOfuWf2608dCg:hover, .hJzDXYJDOfuWf2608dCg:focus, .hJzDXYJDOfuWf2608dCg.VavI2hS_ROyv09afxncc {
  background-color: #627c89;
}

.Cps2UA_pHshkramziIYJ {
  text-align: right;
}

.e92_MtH_2ef2075n4VPJ {
  text-align: center;
}

.AJFBY1kRJG941dOgmN0_ {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.AJFBY1kRJG941dOgmN0_.Cps2UA_pHshkramziIYJ {
  justify-content: end;
}
.AJFBY1kRJG941dOgmN0_.Cps2UA_pHshkramziIYJ .jpmWsZWSVJeFq5Z74E16 {
  order: 1;
}

.jpmWsZWSVJeFq5Z74E16 {
  width: 2rem;
  display: flex;
  align-items: center;
  justify-items: center;
}

.HqtIAanJzOt8bHcaVFLc {
  max-width: 100%;
}

.RygQw2fe_klBg8bIUzKP {
  background-image: linear-gradient(rgba(44, 61, 77, 0.75), rgba(44, 61, 77, 0.75)), var(--team1-logo, ""), var(--team2-logo, "");
  background-position: 0 0, -3rem -2rem, calc(100% + 3rem) -2rem;
  background-size: cover, 40% auto, 40% auto;
  background-repeat: no-repeat;
  padding: 0.5rem 0.5rem 1.5rem;
}

.nWhywWjoIHNHEyaGWMwI {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
  max-width: 350px;
  margin: 0 auto;
}

.Mpoj1WcDxpvgbgeuHY3T {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.rq1L4xvmoBQKhE9DrVPt {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 0.5rem;
}

.dS0HeywCFYzy2QncxGJk {
  display: none;
}
@media only screen and (min-width: 768px) {
  .dS0HeywCFYzy2QncxGJk {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .o_KpKa8zMj6NNT86bCQn {
    display: none;
  }
}

.mmL_7o3XLDQuNPruQf0o {
  opacity: 0.7;
}

.lxykkEq_C17q6zQRPw3v {
  display: flex;
}

.vwFetmBrOUqOSc5FC3uh::before, .vwFetmBrOUqOSc5FC3uh::after {
  height: 1rem;
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 50%;
  margin-right: 0.5rem;
}
.vwFetmBrOUqOSc5FC3uh::before {
  content: "";
  order: 0;
}
.vwFetmBrOUqOSc5FC3uh::after {
  order: 0;
}

.RvAOxRyn41aq4R5z1Ewg {
  order: 1;
}

.jqwRlFU1X9gtWJmYeVmP {
  flex-direction: row-reverse;
}

.oliw2FaPThxms7nCVYL9::before, .oliw2FaPThxms7nCVYL9::after {
  margin-right: unset;
}
.oliw2FaPThxms7nCVYL9::before {
  margin-left: 0.5rem;
  order: 1;
}
.oliw2FaPThxms7nCVYL9::after {
  order: 0;
}

.WHsmkikpYEK2LDd7Y0xU::after, .rUweVvqnutPeJAyLaBsk::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: auto 90%;
}
.WHsmkikpYEK2LDd7Y0xU:not(.oliw2FaPThxms7nCVYL9)::before {
  margin: 0;
}
.WHsmkikpYEK2LDd7Y0xU::after {
  content: "";
}

.lYNun24Tg5Qh20PjV6XO::after, .MZUx5lpSgh1_Cu5GWRS9::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.lYNun24Tg5Qh20PjV6XO:not(.oliw2FaPThxms7nCVYL9)::before {
  margin: 0;
}
.lYNun24Tg5Qh20PjV6XO::after {
  content: "";
}

.gzMWDAxtm1H9KPTEio1i::after, .ro6jTER4jec4eBuzfaT1::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.gzMWDAxtm1H9KPTEio1i:not(.oliw2FaPThxms7nCVYL9)::before {
  margin: 0;
}
.gzMWDAxtm1H9KPTEio1i::after {
  content: "";
}

.Iw1nhFqzfpRWdcWBDeEA::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.ZWCf737sxoWsHZxYttAj {
  display: flex;
  justify-content: center;
  grid-column: 1/4;
}
@media only screen and (min-width: 480px) {
  .ZWCf737sxoWsHZxYttAj {
    position: absolute;
    right: 0;
  }
}

.l8Qd0S6oO3J7FmdWKCIF {
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dy7TDUZ0fRFoSgspOW2g {
  display: inline-block;
  width: 2rem;
  box-sizing: border-box;
  aspect-ratio: 42/24;
  border-style: solid;
  border-color: #9d131a;
  border-width: 1px;
  border-radius: 2px;
  background-color: #262626;
}
.dy7TDUZ0fRFoSgspOW2g::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.25rem 0 0.25rem 0.45rem;
  border-color: transparent transparent transparent white;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/Match.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,oCAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAFF;ACuBU;ED1BV;IAQI,oCAAA;EADF;AACF;;AAIA;EACE,kBAAA;EACA,cAAA;EACA,qBAAA;EACA,YAAA;AADF;;AAIA;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,8BAAA;AADF;;AAIA;EACE,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,wCAAA;EACA,iBAAA;EACA,gBAAA;AADF;ACPU;EDEV;IASI,iBAAA;EAAF;AACF;;AAGA;EACE,eAAA;AAAF;AAEE;EAGE,yBAAA;AAFJ;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAHF;AAKE;EACE,oBAAA;AAHJ;AAKI;EACE,QAAA;AAHN;;AAQA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AALF;;AAQA;EACE,eAAA;AALF;;AAQA;EAGI,+HAAA;EACA,8DACE;EAGF,0CACE;EAGF,4BAAA;EAEF,6BAAA;AAdF;;AAiBA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAdF;;AAiBA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAdF;;AAiBA;EACE,8CAAA;EACA,mBAAA;AAdF;;AAiBA;EACE,aAAA;AAdF;ACjFU;ED8FV;IAII,cAAA;EAbF;AACF;;ACtFU;EDsGV;IAEI,aAAA;EAbF;AACF;;AAgBA;EACE,YAAA;AAbF;;AAgBA;EACE,aAAA;AAbF;;AAiBE;EAEE,YAAA;EACA,eAAA;EAEE,4BAAA;EACA,0BAAA;EACA,wBAAA;EAEF,oBAAA;AAjBJ;AAoBE;EACE,WAAA;EACA,QAAA;AAlBJ;AAoBE;EACE,QAAA;AAlBJ;;AAsBA;EACE,QAAA;AAnBF;;AAsBA;EACE,2BAAA;AAnBF;;AAuBE;EAEE,mBAAA;AArBJ;AAwBE;EACE,mBAAA;EACA,QAAA;AAtBJ;AAyBE;EACE,QAAA;AAvBJ;;AA4BE;EAEE,yDAAA;EACA,yBAAA;AA1BJ;AA+BM;EACE,SAAA;AA7BR;AAiCI;EACE,WAAA;AA/BN;;AAqCE;EAEE,yDAAA;AAnCJ;AAwCM;EACE,SAAA;AAtCR;AA0CI;EACE,WAAA;AAxCN;;AA8CE;EAEE,yDAAA;AA5CJ;AAiDM;EACE,SAAA;AA/CR;AAmDI;EACE,WAAA;AAjDN;;AAuDE;EACE,yDAAA;AApDJ;;AAwDA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AArDF;AChLU;EDkOV;IAMI,kBAAA;IACA,QAAA;EApDF;AACF;;AAuDA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AApDF;;AAuDA;EACE,qBAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EAGE,mBAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;EAEF,yBAAA;AAvDF;AAyDE;EACE,WAAA;EACA,cAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,uCAAA;EACA,uDAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AAvDJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"match": `_37mAUZo6lnKmwXuVUxQ`,
	"description": `Ppye6l9IjEdS7MZ9IUAQ`,
	"rules": `AOVGWswwXJacpZdVFHl1`,
	"matchMain": `XZv33Nt5h7aLTxJaOXUg`,
	"expandable": `hJzDXYJDOfuWf2608dCg`,
	"opened": `VavI2hS_ROyv09afxncc`,
	"alignRight": `Cps2UA_pHshkramziIYJ`,
	"alignCenter": `e92_MtH_2ef2075n4VPJ`,
	"team": `AJFBY1kRJG941dOgmN0_`,
	"teamLogo": `jpmWsZWSVJeFq5Z74E16`,
	"teamLogoImg": `HqtIAanJzOt8bHcaVFLc`,
	"matches": `RygQw2fe_klBg8bIUzKP`,
	"matchList": `nWhywWjoIHNHEyaGWMwI`,
	"players": `Mpoj1WcDxpvgbgeuHY3T`,
	"separator": `rq1L4xvmoBQKhE9DrVPt`,
	"fullName": `dS0HeywCFYzy2QncxGJk`,
	"shortName": `o_KpKa8zMj6NNT86bCQn`,
	"loss": `mmL_7o3XLDQuNPruQf0o`,
	"player": `lxykkEq_C17q6zQRPw3v`,
	"playerRace": `vwFetmBrOUqOSc5FC3uh`,
	"nick": `RvAOxRyn41aq4R5z1Ewg`,
	"playerRight": `jqwRlFU1X9gtWJmYeVmP`,
	"playerRaceRight": `oliw2FaPThxms7nCVYL9`,
	"Terran-1": `WHsmkikpYEK2LDd7Y0xU`,
	"Terran": `rUweVvqnutPeJAyLaBsk`,
	"Zerg-1": `lYNun24Tg5Qh20PjV6XO`,
	"Zerg": `MZUx5lpSgh1_Cu5GWRS9`,
	"Protoss-1": `gzMWDAxtm1H9KPTEio1i`,
	"Protoss": `ro6jTER4jec4eBuzfaT1`,
	"Random": `Iw1nhFqzfpRWdcWBDeEA`,
	"video": `ZWCf737sxoWsHZxYttAj`,
	"videoButton": `l8Qd0S6oO3J7FmdWKCIF`,
	"videoIcon": `dy7TDUZ0fRFoSgspOW2g`
};
export default ___CSS_LOADER_EXPORT___;
