import clsx from 'clsx';
import { FC } from 'react';

import CZSKSvg from '../CZSKSvg';
import { MatchPlayer } from '../../types/match';

import styles from './MatchBox.module.scss';

type MatchBoxProps = {
  player: MatchPlayer;
  position?: 'top' | 'bottom';
  score?: number;
  className?: string;
  isWinner?: boolean;
};

const MatchBox: FC<MatchBoxProps> = ({ player: { race, nick, image }, position, score, className, isWinner }) => {
  return (
    <div
      className={clsx(styles.base, className, {
        [styles.top]: position === 'top',
        [styles.bottom]: position === 'bottom',
      })}
    >
      <span
        className={clsx(styles.player, {
          [styles.noScore]: score === undefined,
        })}
      >
        <span className={styles.race}>{race}</span>
        <span className={styles.nick}>{nick}</span>
        <span className={clsx(styles.score, { [styles.winner]: isWinner })}>{score ?? ''}</span>
      </span>
      <div className={styles.photo}>
        <CZSKSvg className={styles.czsk} />
        <div className={styles.img}>
          <img src={image} />
        </div>
      </div>
    </div>
  );
};

export default MatchBox;
