import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import weekList from '../../data/tl25/roundRobin.json';
import { TeamType } from '../../types/teams';
import streamData from '../../data/tl25/stream.json';

import { teams } from './teams';
import { parseWeeks } from './utils';
import { ParsedWeek } from './types';

type TeamStats = { team: TeamType; points: number; wins: number; draws: number; losses: number; rank: number };
type StatsContextType = {
  stats: Record<string, TeamStats>;
  leaderboard: TeamStats[];
  parsedWeeks: Record<string, ParsedWeek>;
  stream: {
    date: string;
    link: string;
    description?: string;
    week?: string;
    isInFuture: boolean;
  };
};

const STREAM_DURATION = 3;

const date = streamData.date ? new Date(streamData.date) : null;
const now = new Date();
now.setHours(now.getHours() - STREAM_DURATION);
const stream = {
  ...streamData,
  isInFuture: !!date && date > now,
};

const parsedWeeks = parseWeeks(weekList, teams);

const StatsContext = createContext<StatsContextType | undefined>(undefined);

export const StatsProvider: FC<PropsWithChildren> = ({ children }) => {
  const stats = useMemo(
    () =>
      Object.values(parsedWeeks).reduce<Record<string, TeamStats>>((acc, { matches }) => {
        matches.forEach((match) => {
          if (!acc[match.team1.name]) {
            acc[match.team1.name] = { team: match.team1, points: 0, wins: 0, draws: 0, losses: 0, rank: 1 };
          }
          if (!acc[match.team2.name]) {
            acc[match.team2.name] = { team: match.team2, points: 0, wins: 0, draws: 0, losses: 0, rank: 1 };
          }
          if (match.score1 === undefined || match.score2 === undefined || (match.score1 === 0 && match.score2 === 0)) {
            return;
          }
          acc[match.team1.name].points += match.score1;
          acc[match.team2.name].points += match.score2;
          if (match.score1 > match.score2) {
            acc[match.team1.name].wins += 1;
            acc[match.team2.name].losses += 1;
          } else if (match.score2 > match.score1) {
            acc[match.team2.name].wins += 1;
            acc[match.team1.name].losses += 1;
          } else if (match.score2 === match.score1) {
            acc[match.team2.name].draws += 1;
            acc[match.team1.name].draws += 1;
          }
        });
        return acc;
      }, {}),
    [],
  );
  const leaderboard = useMemo(() => {
    let rank = 1;
    return Object.values(stats)
      .sort((a, b) => b.points - a.points)
      .map((team, index, stats) => {
        if (index && stats[index - 1].points !== team.points) {
          rank = index + 1;
        }
        return {
          ...team,
          rank,
        };
      });
  }, [stats]);
  const statsWithRank = useMemo(() => {
    return Object.fromEntries(
      Object.keys(stats).map((team) => {
        const index = leaderboard.findIndex((t) => t.team.name === team);
        return [
          team,
          {
            ...stats[team],
            rank: leaderboard[index].rank,
          },
        ];
      }),
    );
  }, [leaderboard, stats]);

  return (
    <StatsContext.Provider
      value={{
        stats: statsWithRank,
        leaderboard,
        parsedWeeks,
        stream,
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};

export function useStats() {
  const context = useContext(StatsContext);
  if (context === undefined) {
    throw new Error('useStats must be used within a StatsProvider');
  }
  return context;
}
