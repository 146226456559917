// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eueZI6YRelHO_bjvfYNo {
  position: relative;
}

.PYLehtK_mcSe0MsWxl7b {
  display: flex;
  row-gap: 4rem;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .PYLehtK_mcSe0MsWxl7b {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1280px) {
  .PYLehtK_mcSe0MsWxl7b {
    max-width: 1280px;
    margin: 1em auto 0;
  }
}

.TrXMPlj1ncfBscrXVXeE {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(1) {
  z-index: 9;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(2) {
  z-index: 8;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(3) {
  z-index: 7;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(4) {
  z-index: 6;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(5) {
  z-index: 5;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(6) {
  z-index: 4;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(7) {
  z-index: 3;
}
.TrXMPlj1ncfBscrXVXeE:nth-child(8) {
  z-index: 2;
}

.RNBAOydaJqrYg6xdxahu {
  margin-top: 5vw;
}
@media only screen and (max-width: 1279.9px) {
  .RNBAOydaJqrYg6xdxahu {
    margin-top: 7rem;
  }
}

.NmZghod5LKBU1GiWOmCc {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1280px) {
  .UGGAWJLlYLUGUP3aN4qz {
    order: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Group.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;AAHF;;AAMA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAHF;ACoBU;EDrBV;IAOI,mBAAA;IACA,eAAA;EAFF;AACF;ACcU;EDrBV;IAYI,iBAAA;IACA,kBAAA;EADF;AACF;;AAIA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,OAAA;AADF;AAII;EACE,UAAA;AAFN;AACI;EACE,UAAA;AACN;AAFI;EACE,UAAA;AAIN;AALI;EACE,UAAA;AAON;AARI;EACE,UAAA;AAUN;AAXI;EACE,UAAA;AAaN;AAdI;EACE,UAAA;AAgBN;AAjBI;EACE,UAAA;AAmBN;;AAdA;EACE,eAAA;AAiBF;ACXY;EDPZ;IAII,gBAAA;EAkBF;AACF;;AAfA;EACE,aAAA;EACA,sBAAA;AAkBF;;ACrCU;EDsBV;IAEI,QAAA;EAkBF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `eueZI6YRelHO_bjvfYNo`,
	"playerList": `PYLehtK_mcSe0MsWxl7b`,
	"player": `TrXMPlj1ncfBscrXVXeE`,
	"matchList": `RNBAOydaJqrYg6xdxahu`,
	"topSection": `NmZghod5LKBU1GiWOmCc`,
	"topText": `UGGAWJLlYLUGUP3aN4qz`
};
export default ___CSS_LOADER_EXPORT___;
