import { players as playersGeneral } from '../../players';
import { TeamMatchType } from '../../types/match';
import { TeamPlayerType, TeamType } from '../../types/teams';

import { Stats } from './components/Player/types';
import { teams } from './teams';
import { ParsedWeek } from './types';

const raceMap = {
  T: 'Terran',
  P: 'Protoss',
  Z: 'Zerg',
  R: 'Random',
} as const;

const getPlayerData = (playerId: string, race?: string[]) => {
  const playerData = {
    ...(Object.values(teams).find(({ players }) =>
      Object.keys(players).find(
        (teamPlayerId) => teamPlayerId.toLocaleLowerCase().localeCompare(playerId.toLocaleLowerCase()) === 0,
      ),
    )?.players[playerId] ?? {
      ...playersGeneral.unknown,
      nick: playerId,
    }),
  };
  const defaultRace = 'race' in playerData ? playerData.race : 'Random';
  return {
    ...playerData,
    race: [
      ...new Set(
        race && race.length > 0
          ? race.map((race) => {
              const raceShortcut = race.toUpperCase();
              return raceMap[raceShortcut as keyof typeof raceMap] ?? defaultRace;
            })
          : [defaultRace],
      ),
    ],
  };
};

const parseTeamLeagueMatches = (json: unknown[], teams: Record<string, TeamType>, matchDescription: string[] = []) => {
  return json.map<TeamMatchType>((item) => {
    const { team1, team2, score1, score2, matches, videoId } = item as {
      team1: keyof typeof teams;
      team2: keyof typeof teams;
      score1?: number;
      score2?: number;
      videoId?: string;
      matches: {
        players1: string[];
        players2: string[];
        score1?: (0 | 1)[];
        score2?: (0 | 1)[];
        race1?: string[][];
        race2?: string[][];
      }[];
    };
    return {
      team1: teams[team1] ?? { name: team1 },
      team2: teams[team2] ?? { name: team2 },
      score1,
      score2,
      videoId,
      matches:
        matches?.map((match, index) => ({
          players1: match.players1.map((playerId, i) => getPlayerData(playerId, match.race1?.[i])),
          players2: match.players2.map((playerId, i) => getPlayerData(playerId, match.race2?.[i])),
          score1: match.score1 ?? [0, 0],
          score2: match.score2 ?? [0, 0],
          description: matchDescription[index],
        })) ?? [],
    };
  });
};

export const parseWeeks = (
  weekList: Record<
    string,
    {
      dateStart: string;
      dateEnd?: string;
      description?: string[];
      matches: unknown[];
      matchTypes?: string[];
    }
  >,
  teams: Record<string, TeamType>,
): Record<string, ParsedWeek> =>
  Object.fromEntries(
    Object.keys(weekList).map((weekTitle) => {
      const weekData = weekList[weekTitle as keyof typeof weekList];
      return [
        weekTitle,
        {
          start: new Date(weekData.dateStart),
          end: weekData.dateEnd ? new Date(weekData.dateEnd) : null,
          matches: parseTeamLeagueMatches(weekData.matches, teams, weekData.matchTypes),
          description: Array.isArray(weekData.description) ? weekData.description : [],
        },
      ];
    }),
  );

export const getCurrentWeek = (matches: Record<string, ParsedWeek>) => {
  const now = new Date();
  let currentWeek: string | undefined;
  let lastWeek: string | undefined;

  for (const [weekTitle, weekData] of Object.entries(matches)) {
    const start = weekData.start;
    const end = weekData.end || start;
    end.setHours(23, 59, 59, 999);

    if (now >= start && now <= end) {
      currentWeek = weekTitle;
      break;
    }

    lastWeek = weekTitle;
  }

  return currentWeek || lastWeek || Object.keys(matches)[0];
};

export const getWeekMatches = (matches: Record<string, ParsedWeek>, week?: string) => {
  if (week && week in matches) {
    return [week, matches[week]] as [string, ParsedWeek];
  }

  const currentWeek = getCurrentWeek(matches);
  return [currentWeek, matches[currentWeek]] as [string, ParsedWeek];
};

export const getPlayersStats = (players: Record<string, TeamPlayerType>, matches: TeamMatchType[]) => {
  return Object.values(players).reduce<Record<string, Stats>>((acc, { nick }) => {
    const stats: Stats = {
      vsZerg: { wins: 0, losses: 0 },
      vsProtoss: { wins: 0, losses: 0 },
      vsTerran: { wins: 0, losses: 0 },
      twoVsTwo: { wins: 0, losses: 0 },
      threeVsThree: { wins: 0, losses: 0 },
    };

    matches.forEach((match) => {
      const playerMatches =
        match.matches?.filter(({ players1 }) => players1.some((p) => p.nick.localeCompare(nick) === 0)) || [];

      playerMatches.forEach(({ players1, players2, score1, score2 }) => {
        if (players1.length === 1) {
          const gameCount = Math.max(score1.length, score2.length);
          for (let i = 0; i < gameCount; i++) {
            const opponentRace = players2[0]?.race?.[i] ?? players2[0]?.race?.[0];
            const statsKey =
              opponentRace === 'Zerg'
                ? 'vsZerg'
                : opponentRace === 'Terran'
                  ? 'vsTerran'
                  : opponentRace === 'Protoss'
                    ? 'vsProtoss'
                    : false;
            if (!statsKey) continue;
            const gameScore1 = i >= score1.length ? 0 : score1[i];
            const gameScore2 = i >= score2.length ? 0 : score2[i];
            stats[statsKey].wins += gameScore1;
            stats[statsKey].losses += gameScore2;
          }
        } else {
          const statsKey = players1.length === 2 ? 'twoVsTwo' : players1.length === 3 ? 'threeVsThree' : false;
          if (statsKey) {
            stats[statsKey].wins += score1.reduce<number>((acc, score) => acc + score, 0);
            stats[statsKey].losses += score2.reduce<number>((acc, score) => acc + score, 0);
          }
        }
      });
    });

    return {
      ...acc,
      [nick]: stats,
    };
  }, {});
};
