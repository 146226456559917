// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/tl25/bg.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/tl25/captains.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/tl25/bg_header.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/images/tl25/logo_shade.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../assets/images/tl25/logo_shade_vertical.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NkTZfpESuql6ZDLJNOQS {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #091928;
  background-position: 100%;
}

.OLuYzhQrZzQPOfkDilI5 {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.eoNjEDGp2z5N4RR6BMXX {
  max-width: 1750px;
  margin: 0 auto;
}

.qHzBH5BQABzkfdWgeg4v {
  border-bottom: 2px solid #ccc;
}

.griLoPNwJWkXFa_BmVf5 {
  display: flex;
  flex-direction: column;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}), url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-repeat: no-repeat;
  background-size: 360px auto, cover;
  background-position: 50% 60%, 10% 50%;
}
@media only screen and (min-width: 1280px) {
  .griLoPNwJWkXFa_BmVf5 {
    background-size: auto 100%, cover;
    background-position: 100% 100%, 50% 50%;
  }
}
@media only screen and (min-width: 1600px) {
  .griLoPNwJWkXFa_BmVf5 {
    background-position: 70% 100%, 50% 50%;
  }
}

.e_pgMZBs1iyIG9stNi87 {
  margin: 2.5rem auto 7rem;
}
@media only screen and (max-width: 1279.9px) {
  .e_pgMZBs1iyIG9stNi87 {
    padding-bottom: 250px;
  }
}
@media only screen and (min-width: 1280px) {
  .e_pgMZBs1iyIG9stNi87 {
    max-width: 575px;
    margin-left: 5vw;
    margin-right: 0;
  }
}

.AYYD7jaX7nxebtXA2RzF {
  display: flex;
  height: 60px;
  aspect-ratio: 667/329;
  font-size: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: unset;
  box-sizing: border-box;
}

.YC6aJlHygspcfm_mULPu {
  max-width: 1650px;
  line-height: 1.4;
  margin: 3rem 2rem;
}
@media only screen and (min-width: 768px) {
  .YC6aJlHygspcfm_mULPu {
    padding-left: 200px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
    background-repeat: no-repeat;
    background-size: 175px;
    background-position: 0 50%;
  }
}
@media only screen and (min-width: 1280px) {
  .YC6aJlHygspcfm_mULPu {
    background-size: 325px auto;
    width: 32vw;
    padding-left: 375px;
    margin-left: auto;
    margin-right: auto;
  }
}

._GYU9WoL3TmunYE67hgu {
  font-family: "Superline", sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1.1;
  margin: 0;
  text-transform: uppercase;
  font-size: 3.5rem;
}

.RxQtvBlsLqYmT4I7dMMT {
  margin: 0 0 1.5rem;
}

.xorS9Jsw3AZk4pZycW_L {
  margin-bottom: 10rem;
}

.esqiDdot65fF4A6aTI84 {
  columns: 2;
}
@media only screen and (min-width: 480px) {
  .esqiDdot65fF4A6aTI84 {
    columns: 3;
  }
}

.JZM5IZPAnHGWX6MwQlkj.JZM5IZPAnHGWX6MwQlkj {
  grid-template-columns: 2fr 1fr 2fr;
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/styles.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAQA;EAEI,kHAAA;EACA,4BAAA;EACA,sBAAA;EACA,4BAAA;EACA,yBAAA;EACA,yBAAA;AARJ;;AAYA;EACE,iBAAA;EACA,sBAAA;EACA,gBAAA;AATF;;AAYA;EACE,iBAAA;EACA,cAAA;AATF;;AAYA;EACE,6BAAA;AATF;;AAYA;EACE,aAAA;EACA,sBAAA;EAGE,kGAAA;EACA,4BAAA;EACA,kCAAA;EACA,qCAAA;AAXJ;ACFU;EDKV;IAaM,iCAAA;IACA,uCAAA;EAZJ;AACF;ACRU;EDKV;IAmBI,sCAAA;EAZF;AACF;;AAeA;EACE,wBAAA;AAZF;ACDY;EDYZ;IAII,qBAAA;EAXF;AACF;ACtBU;ED4BV;IAQI,gBAAA;IACA,gBAAA;IACA,eAAA;EAVF;AACF;;AAaA;EACE,aAAA;EACA,YAAA;EACA,qBAAA;EAEA,YAAA;EAEE,yDAAA;EACA,oBAAA;EACA,2BAAA;EACA,4BAAA;EAGF,WAAA;EACA,aAAA;EACA,sBAAA;AAdF;;AAiBA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;AAdF;ACjDU;ED4DV;IAMI,mBAAA;IAEE,yDAAA;IACA,4BAAA;IACA,sBAAA;IACA,0BAAA;EAdJ;AACF;AC1DU;ED4DV;IAgBI,2BAAA;IACA,WAAA;IACA,mBAAA;IACA,iBAAA;IACA,kBAAA;EAdF;AACF;;AAiBA;EExGE,oCAAA;EACA,sBAAA;EFyGA,gBAAA;EACA,SAAA;EACA,yBAAA;EACA,iBAAA;AAbF;;AAgBA;EACE,kBAAA;AAbF;;AAgBA;EACE,oBAAA;AAbF;;AAgBA;EACE,UAAA;AAbF;ACxFU;EDoGV;IAII,UAAA;EAZF;AACF;;AAeA;EACE,kCAAA;AAZF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `NkTZfpESuql6ZDLJNOQS`,
	"page": `OLuYzhQrZzQPOfkDilI5`,
	"content": `eoNjEDGp2z5N4RR6BMXX`,
	"sectionBorder": `qHzBH5BQABzkfdWgeg4v`,
	"header": `griLoPNwJWkXFa_BmVf5`,
	"headerContent": `e_pgMZBs1iyIG9stNi87`,
	"logo": `AYYD7jaX7nxebtXA2RzF`,
	"rules": `YC6aJlHygspcfm_mULPu`,
	"heading": `_GYU9WoL3TmunYE67hgu`,
	"ruleDescription": `RxQtvBlsLqYmT4I7dMMT`,
	"space": `xorS9Jsw3AZk4pZycW_L`,
	"maplist": `esqiDdot65fF4A6aTI84`,
	"match": `JZM5IZPAnHGWX6MwQlkj`
};
export default ___CSS_LOADER_EXPORT___;
