// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/race_terran.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/race_zerg.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/race_protoss.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/images/race_random.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MHBmztemg3pKsMFXWOlT {
  position: relative;
  text-align: center;
  padding: 248px 0 0 2rem;
  box-sizing: border-box;
  height: 400px;
  z-index: 1;
  width: max-content;
  min-width: 200px;
}
.MHBmztemg3pKsMFXWOlT::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, var(--player-bg-color, #fff), transparent), linear-gradient(to bottom, var(--player-bg-color-under, #fff), transparent);
  background-size: 157px, 157px;
  background-position: 0 50px, 9px 50px;
  background-repeat: no-repeat;
}
.MHBmztemg3pKsMFXWOlT::after {
  content: attr(data-rank);
  font-family: var(--font-cool);
  font-size: 1.8rem;
  position: absolute;
  top: 57px;
  left: 10px;
  z-index: 1;
  padding: 0 0.5rem 3.5rem 1.5rem;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 1280px) {
  .MHBmztemg3pKsMFXWOlT {
    transform: scale(1.23);
    transform-origin: 50% 100%;
  }
}

.TV_vu66YLdp0RNgjhdDl {
  font-family: var(--font-cool);
  font-weight: 700;
  text-shadow: 0 5px 15px #000;
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
  position: relative;
  z-index: 3;
}

.tud61Qp8Gf7ELgAElTdI {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  mask-image: linear-gradient(to bottom, #000 80%, transparent 100%);
}

.Lh5d2r_vHe2INs_KjQZY::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: 100% 85%;
  background-size: 80%;
}

.IsV5IgvRjZ_B3DQX7uDc::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-position: 80% 80%;
  background-size: 75%;
}

.OppGa_9lCV6ZSMUDTEGw::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-position: 50% 100%;
  background-size: 60%;
}

.rvucEbWo5WUP0j2oUTIY::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-position: 80% 76%;
  background-size: 48%;
}

.iou8fPW3POPLGMLSgery {
  padding: 1.1rem 1rem;
  font-size: 90%;
  position: relative;
  z-index: 2;
  clip-path: polygon(7% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 15%);
  width: 8rem;
  margin: 1.2rem auto 0;
  background-color: var(--player-content-bg, #000);
  transform: translateZ(0);
}

.vJgi1qYdr4l46R9XD0Yp {
  text-align: left;
  padding-right: 0.5rem;
}

.rgF8GzXwAUZJecU_DKIV {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0.2rem;
}
.rgF8GzXwAUZJecU_DKIV:last-child {
  margin-bottom: 0;
}

.fqV2ZQpLuDhjpNMhkiba {
  display: flex;
  justify-self: end;
}
.fqV2ZQpLuDhjpNMhkiba dd {
  width: 2em;
  text-align: right;
}
.fqV2ZQpLuDhjpNMhkiba dd:nth-of-type(2) {
  text-align: left;
}
.fqV2ZQpLuDhjpNMhkiba dd:nth-of-type(2)::before {
  content: ":";
}

.PKzJW5ms7ohUgdvRUXPy img {
  filter: brightness(50%);
}`, "",{"version":3,"sources":["webpack://./src/components/Player/styles.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;AAFF;AAIE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EAEE,oKAAA;EAEA,6BAAA;EACA,qCACE;EAEF,4BAAA;AANN;AAUE;EACE,wBAAA;EACA,6BAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,+BAAA;EACA,4BAAA;AARJ;ACHU;ED1BV;IAyCI,sBAAA;IACA,0BAAA;EARF;AACF;;AAWA;EACE,6BAAA;EACA,gBAAA;EACA,4BAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;AARF;;AAWA;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;EACA,kEAAA;AARF;;AAYE;EAEI,yDAAA;EACA,6BAAA;EACA,oBAAA;AAVN;;AAgBE;EAEI,yDAAA;EACA,4BAAA;EACA,oBAAA;AAdN;;AAoBE;EAEI,yDAAA;EACA,6BAAA;EACA,oBAAA;AAlBN;;AAwBE;EAEI,yDAAA;EACA,4BAAA;EACA,oBAAA;AAtBN;;AA2BA;EACE,oBAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,mEAAA;EACA,WAAA;EACA,qBAAA;EACA,gDAAA;EACA,wBAAA;AAxBF;;AA2BA;EACE,gBAAA;EACA,qBAAA;AAxBF;;AA2BA;EACE,WAAA;EACA,aAAA;EACA,gCAAA;EACA,qBAAA;AAxBF;AA0BE;EACE,gBAAA;AAxBJ;;AA4BA;EACE,aAAA;EACA,iBAAA;AAzBF;AA2BE;EACE,UAAA;EACA,iBAAA;AAzBJ;AA2BI;EACE,gBAAA;AAzBN;AA2BM;EACE,YAAA;AAzBR;;AAgCE;EACE,uBAAA;AA7BJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MHBmztemg3pKsMFXWOlT`,
	"nick": `TV_vu66YLdp0RNgjhdDl`,
	"photo": `tud61Qp8Gf7ELgAElTdI`,
	"Terran": `Lh5d2r_vHe2INs_KjQZY`,
	"Zerg": `IsV5IgvRjZ_B3DQX7uDc`,
	"Protoss": `OppGa_9lCV6ZSMUDTEGw`,
	"Random": `rvucEbWo5WUP0j2oUTIY`,
	"content": `iou8fPW3POPLGMLSgery`,
	"stats": `vJgi1qYdr4l46R9XD0Yp`,
	"statsRow": `rgF8GzXwAUZJecU_DKIV`,
	"score": `fqV2ZQpLuDhjpNMhkiba`,
	"defeated": `PKzJW5ms7ohUgdvRUXPy`
};
export default ___CSS_LOADER_EXPORT___;
