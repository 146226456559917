import clsx from 'clsx';
import { useState } from 'react';

import Footer from '../../components/Footer';

import { Leaderboard } from './components/Leaderboard';
import { useStats } from './StatsContext';
import { Stream } from './components/Stream';
import styles from './styles.module.scss';
import TeamLeagueWeeks from './components/TeamLeagueWeeks';
import { getCurrentWeek, getWeekMatches } from './utils';
import { WeekMatches } from './components/WeekMatches';
import { ShowScore } from './constants';

export const Overview = () => {
  const {
    parsedWeeks,
    stream: { week: streamWeek },
  } = useStats();

  const [matchWeek, setMatchWeek] = useState(parseInt((streamWeek || getCurrentWeek(parsedWeeks)).replace(/\D/g, '')));
  const [title, { start, end, matches }] = getWeekMatches(parsedWeeks, `Week ${matchWeek}`);

  return (
    <main className={clsx(styles.page, styles.bg)}>
      <header className={clsx(styles.sectionBorder, styles.header)}>
        <div className={styles.headerContent}>
          <h1 className={styles.logo}>Team League 2025</h1>
          <WeekMatches
            title={title}
            start={start}
            end={end}
            matches={matches}
            showScore={ShowScore.Known}
            showDate={!streamWeek}
            matchClassName={styles.match}
            disablePrevious={matchWeek <= 1}
            disableNext={matchWeek >= 10}
            onWeekChange={(change) => setMatchWeek((currWeek) => currWeek + change)}
          />
        </div>
        <Stream />
      </header>
      <section className={styles.sectionBorder}>
        <div className={styles.rules}>
          <h2 className={styles.heading}>Draft</h2>
          <p className={styles.ruleDescription}>6 kapitánů si v tzv. draftu vybere svůj tým z přihlášených hráčů.</p>

          <h2 className={styles.heading}>Základní část</h2>
          <p className={styles.ruleDescription}>
            Týmy následně mezi sebou hrají základní část systémem 2x každý s každým.
          </p>

          <h2 className={styles.heading}>Play-off</h2>
          <p className={styles.ruleDescription}>4 týmy s nejvyšším počtem bodů postupují do play off.</p>

          <h2 className={styles.heading}>Formát zápasů</h2>
          <p className={styles.ruleDescription}>
            Formát clan warů: 4x 1v1, 1x 2v2, + jedna speciální týmová disciplína. Všechny utkání jsou BO2 (zápas může
            skončit remízou). Každá výhra znamená jeden bod do tabulky. Proti sobě nastupují hráči s podobnou
            výkonností, čemuž napomáhá rozdělení hráčů do výkonnostních košů (hráči jsou rozděleni podle aktuálního mmr
            na oficiálním SC:BW ladderu).
          </p>

          <h2 className={styles.heading}>Mapy</h2>
          <div className={styles.ruleDescription}>
            <ul className={styles.maplist}>
              <li>Eclipse</li>
              <li>Deja Vu</li>
              <li>Neo Dark Origin</li>
              <li>Sylphid</li>
              <li>Gladiator</li>
              <li>Pole Star</li>
              <li>Metropolis</li>
              <li>Radeon</li>
            </ul>
          </div>
        </div>
      </section>
      <section className={styles.content}>
        <h2 className="visually-hidden">Aktuální pořadí</h2>
        <Leaderboard />
      </section>
      <section className={clsx(styles.content, styles.space)}>
        <TeamLeagueWeeks weeks={parsedWeeks} />
      </section>
      <Footer />
    </main>
  );
};
