// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awYyZ3MVLyEnZnSu4AnO {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hXLwa3c8LLqelbfBdmgJ {
  margin: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
@media only screen and (min-width: 1850px) {
  .hXLwa3c8LLqelbfBdmgJ {
    margin: 4rem 0;
    display: grid;
    grid-template-areas: "info     players" "captain  players";
    grid-template-columns: 1fr 2.7fr;
    grid-template-rows: auto 650px;
    gap: 2rem;
    align-items: end;
    justify-items: center;
  }
}

@media only screen and (max-width: 767.9px) {
  .jscxqylz7Gd0gRvHQTW5 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .jscxqylz7Gd0gRvHQTW5 {
    grid-area: info;
    display: grid;
    grid-template-areas: "shortcut shortcut" "logo     name" "logo     ranking" "logo     captain";
    grid-template-columns: 1fr 2fr;
    gap: 1rem 1.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1849.9px) {
  .jscxqylz7Gd0gRvHQTW5 {
    text-align: right;
    max-width: 600px;
  }
}

.ikuN51WTqjvkQbECKZ9X {
  grid-area: shortcut;
  font-weight: 900;
  font-size: 7rem;
  text-align: center;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ikuN51WTqjvkQbECKZ9X {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 767.9px) {
  .ikuN51WTqjvkQbECKZ9X {
    font-size: 23cqi;
  }
}

.YEbUr2eW8MMq0pX15jXt {
  grid-area: logo;
  max-height: 250px;
}
@media only screen and (min-width: 1850px) {
  .YEbUr2eW8MMq0pX15jXt {
    max-height: 150px;
  }
}

.ryfnuoE6pVZvcKogUCyD {
  grid-area: name;
  font-weight: 400;
  margin: 0;
}

.KIIDz0imNLjF5ejZZ6YA {
  grid-area: captain;
}
@media only screen and (max-width: 767.9px) {
  .KIIDz0imNLjF5ejZZ6YA {
    margin-top: 3rem;
  }
}
.KIIDz0imNLjF5ejZZ6YA small {
  font-size: 80%;
}

.UXyh5idV9iLY7mAfFXvP {
  text-transform: uppercase;
  font-size: 1.6rem;
}

.OoO5cXCQMERYc4Y6ehtw {
  grid-area: ranking;
  font-weight: 900;
  font-size: 2.6rem;
}

.CRj5571SlwLtIBNffIMU {
  grid-area: players;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
}
@media only screen and (min-width: 768px) {
  .CRj5571SlwLtIBNffIMU {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 1.5rem;
  }
}

.wWoqu_6nWF4UzBajaFTR {
  width: 85vw;
}
@media only screen and (min-width: 768px) {
  .wWoqu_6nWF4UzBajaFTR {
    max-width: 350px;
  }
}
@media only screen and (min-width: 1280px) {
  .wWoqu_6nWF4UzBajaFTR {
    max-width: none;
    width: 380px;
  }
}

.fRVN7nGnM_Tem2wJZpuf {
  grid-area: captain;
}
@media only screen and (min-width: 1850px) {
  .fRVN7nGnM_Tem2wJZpuf {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 1850px) {
  .fRVN7nGnM_Tem2wJZpuf .wWoqu_6nWF4UzBajaFTR {
    width: 100%;
    max-width: none;
    max-height: 580px;
  }
}

.w5YFhbsfODY424h8GNMA {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 5rem;
}
@media only screen and (min-width: 768px) {
  .w5YFhbsfODY424h8GNMA {
    max-width: 800px;
    margin: 7rem auto 3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/TeamDetail.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAQA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAPF;;AAUA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAPF;ACgBU;EDdV;IAQI,cAAA;IACA,aAAA;IACA,0DACE;IAEF,gCAAA;IACA,8BAAA;IACA,SAAA;IACA,gBAAA;IACA,qBAAA;EARF;AACF;;ACoBY;EDTZ;IAEI,aAAA;IACA,sBAAA;IACA,SAAA;IACA,mBAAA;EARF;AACF;ACLU;EDOV;IASI,eAAA;IACA,aAAA;IACA,8FACE;IAIF,8BAAA;IACA,gBAAA;EAXF;AACF;ACNY;EDDZ;IAqBI,iBAAA;IACA,gBAAA;EAVF;AACF;;AAaA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;AAVF;AC5BU;EDiCV;IAQI,mBAAA;EATF;AACF;ACjBY;EDiBZ;IAYI,gBAAA;EARF;AACF;;AAWA;EACE,eAAA;EACA,iBAAA;AARF;AC3CU;EDiDV;IAKI,iBAAA;EAPF;AACF;;AAUA;EACE,eAAA;EACA,gBAAA;EACA,SAAA;AAPF;;AAUA;EACE,kBAAA;AAPF;AC1CY;EDgDZ;IAII,gBAAA;EANF;AACF;AAQE;EACE,cAAA;AANJ;;AAUA;EACE,yBAAA;EACA,iBAAA;AAPF;;AAUA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;AAPF;;AAUA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;AAPF;ACrFU;EDuFV;IAQI,mBAAA;IACA,eAAA;IACA,gBAAA;EANF;AACF;;AASA;EACE,WAAA;AANF;AChGU;EDqGV;IAII,gBAAA;EALF;AACF;ACrGU;EDqGV;IAQI,eAAA;IACA,YAAA;EAJF;AACF;;AAOA;EACE,kBAAA;AAJF;AC/GU;EDkHV;IAII,aAAA;IACA,qBAAA;IACA,WAAA;IACA,YAAA;EAHF;AACF;ACvHU;ED4HR;IAEI,WAAA;IACA,eAAA;IACA,iBAAA;EAHJ;AACF;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAJF;ACrIU;EDqIV;IAOI,gBAAA;IACA,sBAAA;EAHF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `awYyZ3MVLyEnZnSu4AnO`,
	"container": `hXLwa3c8LLqelbfBdmgJ`,
	"info": `jscxqylz7Gd0gRvHQTW5`,
	"shortcut": `ikuN51WTqjvkQbECKZ9X`,
	"logo": `YEbUr2eW8MMq0pX15jXt`,
	"name": `ryfnuoE6pVZvcKogUCyD`,
	"captainName": `KIIDz0imNLjF5ejZZ6YA`,
	"uppercase": `UXyh5idV9iLY7mAfFXvP`,
	"ranking": `OoO5cXCQMERYc4Y6ehtw`,
	"playerList": `CRj5571SlwLtIBNffIMU`,
	"playerCard": `wWoqu_6nWF4UzBajaFTR`,
	"captain": `fRVN7nGnM_Tem2wJZpuf`,
	"matches": `w5YFhbsfODY424h8GNMA`
};
export default ___CSS_LOADER_EXPORT___;
