// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dxTM2K8lEmLDbQjFhaDJ {
  font-family: "Redzone Classic Caps", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: left;
  line-height: 1.04;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: center;
}

.RPXNOcRTTLzhdTcgp7aQ {
  display: block;
  aspect-ratio: 12/15;
  height: 2rem;
}

.zSCpDRFLeww1dValfoUT {
  color: var(--players-advances-color);
}
.zSCpDRFLeww1dValfoUT .RPXNOcRTTLzhdTcgp7aQ {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/QualificationOutcome.module.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAIA;ECHE,+CAAA;EDKA,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,+BAAA;EACA,kBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,cAAA;EACA,mBAAA;EACA,YAAA;AAHF;;AAMA;EACE,oCAAA;AAHF;AAKE;EACE,yBAAA;AAHJ","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `dxTM2K8lEmLDbQjFhaDJ`,
	"arrow": `RPXNOcRTTLzhdTcgp7aQ`,
	"advances": `zSCpDRFLeww1dValfoUT`
};
export default ___CSS_LOADER_EXPORT___;
