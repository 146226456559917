// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/bg_default_mobile.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/bg_default.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vxKLp6WiyjmIIzLNbz2W {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2e2e2e;
  min-height: 100vh;
  box-sizing: border-box;
}
@media only screen and (min-width: 1280px) {
  .vxKLp6WiyjmIIzLNbz2W {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-attachment: fixed;
  }
}

._99Phbpap1Qi_IQYXxPTI {
  font-family: var(--font-cool);
  text-transform: uppercase;
  text-wrap: nowrap;
  transform: rotate(-4deg) translateY(-30%);
  text-align: center;
  margin: 0;
  font-family: "Redzone Classic Caps", sans-serif;
  margin-top: 2rem;
  font-size: 1.6rem;
  color: #fff;
}
@media only screen and (min-width: 480px) {
  ._99Phbpap1Qi_IQYXxPTI {
    font-size: 2.2rem;
  }
}
@media only screen and (min-width: 768px) {
  ._99Phbpap1Qi_IQYXxPTI {
    font-size: 3rem;
  }
}
@media only screen and (min-width: 1280px) {
  ._99Phbpap1Qi_IQYXxPTI {
    margin-top: 3rem;
    position: relative;
  }
}
._99Phbpap1Qi_IQYXxPTI + * {
  transform: translateY(-2rem);
}
@media only screen and (min-width: 1280px) {
  ._99Phbpap1Qi_IQYXxPTI + * {
    transform: translateY(-5rem);
  }
}

.h0PiqYmNIEIexlvDfDBx {
  font-family: var(--font-cool);
  text-transform: uppercase;
  text-wrap: nowrap;
  transform: rotate(-4deg) translateY(-30%);
  text-align: center;
  margin: 0;
  font-size: 5rem;
  margin-top: 2rem;
  color: var(--group-title-color);
  -webkit-text-stroke: 1.5px var(--group-title-border-color);
}
@media only screen and (min-width: 480px) {
  .h0PiqYmNIEIexlvDfDBx {
    font-size: 7rem;
  }
}
@media only screen and (min-width: 768px) {
  .h0PiqYmNIEIexlvDfDBx {
    font-size: 10rem;
  }
}
@media only screen and (min-width: 1280px) {
  .h0PiqYmNIEIexlvDfDBx {
    margin-top: 5rem;
    position: relative;
  }
}

.th4prZqpOCIXZXmQbfxA {
  display: inline-block;
  width: 0;
}

.QwRe49LU8TurAUCAwkV3 {
  overflow: hidden;
  position: relative;
}
.QwRe49LU8TurAUCAwkV3::after {
  font-family: var(--font-cool);
  position: absolute;
  top: 0;
  left: -5.6vw;
  letter-spacing: -1.5vw;
  content: "CZSK League";
  text-transform: uppercase;
  font-style: italic;
  font-size: 30vw;
  text-wrap: nowrap;
  color: transparent;
  -webkit-text-stroke: 1px var(--bg-text-color, #fff);
}
@media only screen and (max-width: 1279.9px) {
  .QwRe49LU8TurAUCAwkV3::after {
    font-size: 30rem;
    transform: translateX(-4%);
  }
}
@media only screen and (max-width: 767.9px) {
  .QwRe49LU8TurAUCAwkV3::after {
    font-size: 25rem;
  }
}

@media only screen and (min-width: 1280px) {
  .Q0s6PVKXxEFqGepBMEvx::after {
    position: fixed;
    left: 0.65vw;
  }
}

._26eTothro9SFtoBiKhK {
  font-size: 2em;
  line-height: 1;
  font-weight: 900;
  color: var(--subheading-color);
  text-align: center;
  text-transform: uppercase;
  margin: 1em 0 0.5em;
}
@media only screen and (min-width: 768px) {
  ._26eTothro9SFtoBiKhK {
    font-size: 2.6em;
  }
}

.U5Kavt_bERpQeARG2Be9 {
  font-size: 1.5em;
}

.guljVmcNGUNMIZOOpvpA {
  text-shadow: 0 5px 10px #000;
}`, "",{"version":3,"sources":["webpack://./src/styles/page.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAIA;EAEI,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;EAEF,iBAAA;EACA,sBAAA;AALF;ACsBU;EDzBV;IAYM,yDAAA;IACA,4BAAA;EALJ;AACF;;AASA;EEbE,6BAAA;EACA,yBAAA;EACA,iBAAA;EACA,yCAAA;EACA,kBAAA;EACA,SAAA;EAbA,+CAAA;EFwBA,gBAAA;EACA,iBAAA;EACA,WAAA;AADF;ACGU;EDPV;IAQI,iBAAA;EAAF;AACF;ACFU;EDPV;IAYI,eAAA;EACF;AACF;ACPU;EDPV;IAgBI,gBAAA;IACA,kBAAA;EAEF;AACF;AAAE;EACE,4BAAA;AAEJ;AChBU;EDaR;IAII,4BAAA;EAGJ;AACF;;AACA;EE1CE,6BAAA;EACA,yBAAA;EACA,iBAAA;EACA,yCAAA;EACA,kBAAA;EACA,SAAA;EFuCA,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,0DAAA;AAOF;AClCU;EDsBV;IAQI,eAAA;EAQF;AACF;ACvCU;EDsBV;IAYI,gBAAA;EASF;AACF;AC5CU;EDsBV;IAgBI,gBAAA;IACA,kBAAA;EAUF;AACF;;AAPA;EACE,qBAAA;EACA,QAAA;AAUF;;AAPA;EACE,gBAAA;EACA,kBAAA;AAUF;AARE;EACE,6BAAA;EACA,kBAAA;EACA,MAAA;EACA,YAAA;EACA,sBAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mDAAA;AAUJ;AC1DY;EDoCV;IAeI,gBAAA;IACA,0BAAA;EAWJ;AACF;AChEY;EDoCV;IAoBI,gBAAA;EAYJ;AACF;;ACrFU;ED8ER;IAEI,eAAA;IACA,YAAA;EAUJ;AACF;;AANA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AASF;ACtGU;EDsFV;IAUI,gBAAA;EAUF;AACF;;AAPA;EACE,gBAAA;AAUF;;AAPA;EACE,4BAAA;AAUF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `vxKLp6WiyjmIIzLNbz2W`,
	"pageSectionTitle": `_99Phbpap1Qi_IQYXxPTI`,
	"title": `h0PiqYmNIEIexlvDfDBx`,
	"emptyChar": `th4prZqpOCIXZXmQbfxA`,
	"czskLeagueText": `QwRe49LU8TurAUCAwkV3`,
	"czskLeagueTextFixed": `Q0s6PVKXxEFqGepBMEvx`,
	"subheading": `_26eTothro9SFtoBiKhK`,
	"subheadingSmall": `U5Kavt_bERpQeARG2Be9`,
	"subheadingShade": `guljVmcNGUNMIZOOpvpA`
};
export default ___CSS_LOADER_EXPORT___;
