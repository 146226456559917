import clsx from 'clsx';

import { Group } from '../../components/Group';
import LinkList from '../../components/LinkList';
import matchList from '../../data/s5/finals/groupA.json';
import { Routes } from '../../routes';
import generalStyles from '../../styles/page.module.scss';
import { GroupFormat } from '../../tournament';
import { parseMatches } from '../../utils';
import Footer from '../../components/Footer';
import playerStyles from '../../components/Player/styles.module.scss';
import PlayerResult from '../../components/Player/PlayerResult';

import { players } from './players';
import styles from './styles.module.scss';

const playerList = [
  { ...players.MetodMan, rank: 1, content: <PlayerResult>5:3</PlayerResult> },
  {
    ...players.BaB,
    rank: 2,
    content: <PlayerResult>5:2</PlayerResult>,
  },
  {
    ...players.Masicko,
    rank: 3,
    content: <PlayerResult>2:4</PlayerResult>,
    className: playerStyles.defeated,
  },
  {
    ...players.Striker,
    rank: 4,
    content: <PlayerResult>2:5</PlayerResult>,
    className: playerStyles.defeated,
  },
];

const matches = parseMatches(matchList, players);

export const GroupA = () => {
  return (
    <main
      className={clsx(
        generalStyles.page,
        generalStyles.czskLeagueText,
        generalStyles.czskLeagueTextFixed,
        styles.colors,
      )}
    >
      <div className={styles.container}>
        <header className={generalStyles.pageSectionTitle}>Offline finále</header>
        <Group
          players={playerList}
          title="Skupina A"
          groupFormat={GroupFormat.RoundRobin}
          matches={matches}
          topText={
            <section className={styles.description}>
              <p>O titul šampiona se utká 8 hráčů, kteří budou rozdělení do&nbsp;dvou skupin.</p>
              <h2 className={clsx(generalStyles.subheading, generalStyles.subheadingSmall, styles.subheading)}>
                Formát skupin
              </h2>
              <p>
                V&nbsp;každé skupině odehraje každý s&nbsp;každým jeden vzájemný best-of-3 zápas (výhra 2&nbsp;body,
                prohra 0&nbsp;bodů).
              </p>
              <p>
                Nejlepší dva ze&nbsp;skupin postoupí do&nbsp;semifinále. Při rovnosti bodů se rozhoduje v&nbsp;pořadí:
                vzájemný zápas, skóre W/L, tiebreak - mini turnaj best-of-1.
              </p>
            </section>
          }
        />
      </div>

      <LinkList
        items={[
          { name: 'Skupina B', to: Routes.S5_FINALS_GROUP_B.path },
          {
            name: (
              <>
                Pla<span className={generalStyles.emptyChar}></span>y-off
              </>
            ),
            to: Routes.S5_FINALS_PLAYOFF.path,
          },
          { name: 'Informace', to: Routes.S5_FINALS.path },
        ]}
      />
      <Footer />
    </main>
  );
};
