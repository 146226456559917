import clsx from 'clsx';
import { FC, Fragment } from 'react';

import matchStyles from '../styles/match.module.scss';
import pageStyles from '../styles/page.module.scss';
import { MatchType } from '../types/match';

import Match from './Match';

type MatchListPhasesProps = { matches: Record<string, MatchType[]>; className?: string };

const MatchListPhases: FC<MatchListPhasesProps> = ({ matches, className }) => {
  return (
    <section className={className}>
      <h2 className="visually-hidden">Zápasy</h2>
      {Object.keys(matches).map((title) => (
        <Fragment key={title}>
          <h3 className={clsx(pageStyles.subheading, pageStyles.subheadingShade)}>{title}</h3>
          <ul className={matchStyles.matchList}>
            {matches[title].map((match, i) => (
              <li key={i}>
                <Match {...match} />
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </section>
  );
};

export default MatchListPhases;
