// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/twitch.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBvSDTX_UNalWPElEh_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.BnwXhkklNV74AA52ZLCn {
  --button-link-border-color: #fff;
  opacity: 1;
  justify-content: flex-start;
  position: relative;
  gap: 6.5rem;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #9246ff;
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: 72px 50%;
  max-width: 70vw;
}
@media only screen and (min-width: 480px) {
  .BnwXhkklNV74AA52ZLCn {
    max-width: max-content;
  }
}
@media only screen and (min-width: 1280px) {
  .BnwXhkklNV74AA52ZLCn {
    box-sizing: border-box;
  }
}
.BnwXhkklNV74AA52ZLCn::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 31.5px solid #fff;
  margin-top: 0.5rem;
}

.VKApWQe25Ut2i1Xr8VF3 {
  padding-right: 4rem;
}
@media only screen and (min-width: 1280px) {
  .VKApWQe25Ut2i1Xr8VF3 {
    padding-right: 5rem;
  }
}
.VKApWQe25Ut2i1Xr8VF3::after {
  content: "Live";
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  font-family: var(--font-normal);
  font-size: 60%;
  font-weight: 600;
  text-transform: uppercase;
  color: red;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
  animation: eJL7MngWvOeEMNkbDBtj 1.5s infinite;
}
@keyframes eJL7MngWvOeEMNkbDBtj {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.gR6Rq0Wj2qvYLO4qfhUs {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/LinkTwitch.module.scss","webpack://./src/styles/conf/_colors.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAJF;;AAOA;EAGE,gCAAA;EACA,UAAA;EACA,2BAAA;EACA,kBAAA;EACA,WAAA;EAEE,yDAAA;EACA,yBCdK;EDeL,4BAAA;EACA,yBAAA;EACA,6BAAA;EAGF,eAAA;AATF;AEUU;EFjBV;IAmBI,sBAAA;EARF;AACF;AEKU;EFjBV;IAuBI,sBAAA;EAPF;AACF;AASE;EACE,WAAA;EACA,qBAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,qCAAA;EACA,8BAAA;EACA,kBAAA;AAPJ;;AAWA;EACE,mBAAA;AARF;AEdU;EFqBV;IAII,mBAAA;EAPF;AACF;AASE;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,+BAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;EACA,UAAA;EACA,yCAAA;EACA,6CAAA;AAPJ;AASI;EACE;IACE,UAAA;EAPN;EASI;IACE,YAAA;EAPN;EASI;IACE,UAAA;EAPN;AACF;;AAYA;EACE,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;AATF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PBvSDTX_UNalWPElEh_2`,
	"linkTwitch": `BnwXhkklNV74AA52ZLCn`,
	"linkTwitchLive": `VKApWQe25Ut2i1Xr8VF3`,
	"pulse": `eJL7MngWvOeEMNkbDBtj`,
	"content": `gR6Rq0Wj2qvYLO4qfhUs`
};
export default ___CSS_LOADER_EXPORT___;
