// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e2zBF6biWooqTAf6a9CJ {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
@media only screen and (min-width: 1280px) {
  .e2zBF6biWooqTAf6a9CJ {
    gap: 10rem;
  }
}

.VmLUqqVQTJv_jZcmmdQE.VmLUqqVQTJv_jZcmmdQE {
  max-width: 900px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .VmLUqqVQTJv_jZcmmdQE.VmLUqqVQTJv_jZcmmdQE {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    align-items: start;
    column-gap: 2.5rem;
  }
}

.ovzqOwejxFcMQbIZqiKE.ovzqOwejxFcMQbIZqiKE {
  font-family: "Superline", sans-serif;
  letter-spacing: 0.1rem;
  font-size: 5rem;
}

.VuNhA7MMsjk8Oo5uoOvO {
  font-size: 1.3rem;
}
@media only screen and (min-width: 768px) {
  .VuNhA7MMsjk8Oo5uoOvO {
    grid-row: 2;
  }
}

@media only screen and (min-width: 768px) {
  .ESyw2iM7AO4xQ4rWzOgT {
    grid-row: 1/3;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/tl25/components/TeamLeagueWeeks.module.scss","webpack://./src/styles/mixins/_media.scss","webpack://./src/styles/mixins/_font.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHF;ACyBU;EDzBV;IAMI,UAAA;EAFF;AACF;;AAKA;EACE,gBAAA;EACA,cAAA;AAFF;ACeU;EDfV;IAKI,aAAA;IACA,+BAAA;IACA,4BAAA;IACA,kBAAA;IACA,kBAAA;EADF;AACF;;AAIA;EElBE,oCAAA;EACA,sBAAA;EFmBA,eAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;ACJU;EDGV;IAII,WAAA;EACF;AACF;;ACTU;EDWV;IAEI,aAAA;EACF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `e2zBF6biWooqTAf6a9CJ`,
	"wrapper": `VmLUqqVQTJv_jZcmmdQE`,
	"weekTitle": `ovzqOwejxFcMQbIZqiKE`,
	"dates": `VuNhA7MMsjk8Oo5uoOvO`,
	"matches": `ESyw2iM7AO4xQ4rWzOgT`
};
export default ___CSS_LOADER_EXPORT___;
