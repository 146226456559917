import clsx from 'clsx';
import { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../../routes';
import { inflex } from '../../../utils';
import { useStats } from '../StatsContext';

import styles from './Leaderboard.module.scss';

export const Leaderboard: FC = () => {
  const { leaderboard } = useStats();

  return (
    <ol className={styles.wrapper}>
      {leaderboard.map(({ team, points, wins, draws, losses, rank }, index) => {
        return (
          <li
            key={index}
            className={styles.item}
            style={
              {
                '--team-card-border-color': team.card?.borderColor,
                '--team-card-gradient-1': team.card?.bgGradientTop,
                '--team-card-gradient-2': team.card?.bgGradientBottom,
              } as CSSProperties
            }
          >
            <Link to={`${Routes['TL25']['routes'][team.route].path}`} className={clsx(styles.link, styles.card)}>
              <img src={team.imageStandalone ?? team.image} alt={`${team.name} logo`} className={styles.logo} />
              <span className={styles.name}>{team.name}</span>
              <span className={styles.ranking}>#{rank}</span>
              <span className={styles.stats}>
                <span className={clsx(styles.statsItem, styles.points)}>
                  {points} {inflex(points, ['bod', 'body', 'bodů'])}
                </span>
                <span className={styles.statsItem}>
                  {wins} {inflex(wins, ['vítězství', 'vítězství', 'vítězství'])}
                </span>
                <span className={styles.statsItem}>
                  {draws} {inflex(draws, ['remíza', 'remízy', 'remíz'])}
                </span>
                <span className={styles.statsItem}>
                  {losses} {inflex(losses, ['porážka', 'porážky', 'porážek'])}
                </span>
              </span>
            </Link>
          </li>
        );
      })}
    </ol>
  );
};
