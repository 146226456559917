import { FC } from 'react';

import { ParsedWeek } from '../types';
import { ShowScore } from '../constants';

import { WeekMatches } from './WeekMatches';
import styles from './TeamLeagueWeeks.module.scss';

type TeamLeagueWeeksProps = { weeks: Record<string, ParsedWeek> };

const TeamLeagueWeeks: FC<TeamLeagueWeeksProps> = ({ weeks }) => {
  const now = new Date();
  return (
    <ul className={styles.list}>
      {Object.keys(weeks).map((title) => (
        <li className={styles.week} key={title}>
          <WeekMatches
            title={title}
            description={weeks[title].description}
            start={weeks[title].start}
            end={weeks[title].end}
            matches={weeks[title].matches}
            showScore={(weeks[title].end ?? 0) <= now ? ShowScore.All : ShowScore.None}
            expandable
            className={styles.wrapper}
            weekClassName={styles.weekTitle}
            datesClassName={styles.dates}
            matchesClassName={styles.matches}
            fullName
          />
        </li>
      ))}
    </ul>
  );
};

export default TeamLeagueWeeks;
