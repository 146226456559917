import { FC } from 'react';

import styles from './styles.module.scss';

const CloseButton: FC = () => {
  return (
    <label className={styles.toggle} htmlFor="menu">
      <svg
        width="35"
        height="35"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </label>
  );
};

export default CloseButton;
