import clsx from 'clsx';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo_text.webp';
import LinkList from '../../components/LinkList';
import { Routes } from '../../routes';
import generalStyles from '../../styles/page.module.scss';
import Footer from '../../components/Footer';
import LinkTwitch from '../../components/LinkTwitch';

import styles from './Finals.module.scss';
import pageStyles from './styles.module.scss';

const eventStart = new Date('2024-09-28T10:00:00+02:00');
const eventEnd = new Date('2024-09-28T21:00:00+02:00');

export const Finals = () => {
  const now = new Date();
  const isStreamLive = now >= eventStart && now <= eventEnd;
  return (
    <div className={clsx(styles.page, generalStyles.czskLeagueText, pageStyles.colors)}>
      <main>
        <div className={clsx(styles.wrapper, styles.main)}>
          <h1 className={styles.title}>Offline finále</h1>
        </div>

        <section>
          <h2 className="visually-hidden">Hráči</h2>

          <ul className={styles.players}>
            <li>Vaclavson</li>
            <li>Crow</li>
            <li>MetodMan</li>
            <li>izu</li>
            <li>Syrax</li>
            <li>BaB</li>
            <li>Masicko</li>
            <li>Striker</li>
          </ul>
        </section>

        <section className={styles.info} itemScope itemType="http://schema.org/Event">
          <meta itemProp="name" content="Offline finále 5. ročníku CZSK StarCraft League" />
          <meta itemProp="image" content={logo} />
          <span className={styles.infoDate} itemProp="startDate" content={eventStart.toISOString()}>
            28. 9. Praha
          </span>
          <meta itemProp="endDate" content={eventEnd.toISOString()} />
          <span className={styles.infoTime}>9:00 - 21:00</span>
          <span className={styles.infoLocation} itemProp="location" itemScope itemType="http://schema.org/Place">
            <span itemProp="name" content="Praha, Magenta Experience Center">
              Magenta Experience Center
            </span>
          </span>
        </section>

        <section className={styles.section}>
          <p>
            Zveme Vás na&nbsp;velké finále 5. ročníku CZSK StarCraft ligy v&nbsp;legendární počítačové hře StarCraft:
            Brood War, které proběhne formou offline turnaje. Tento epický závěr sezóny se uskuteční
            28.&nbsp;září&nbsp;2024 v Magenta Experience Center v&nbsp;Praze! Nejlepší hráči se utkají o&nbsp;titul
            šampiona a atraktivní ceny. Přijďte podpořit své favority a prožít nezapomenutelné momenty finále.
          </p>
        </section>

        <LinkList
          items={[
            <LinkTwitch
              key="censured"
              label="Censured"
              to="https://www.twitch.tv/censured"
              isLive={isStreamLive}
              target="https://www.twitch.tv/censured"
            />,
          ]}
        />

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Výhry</h3>
          <ol className={styles.prizes}>
            <li>
              <h4 className={clsx(generalStyles.subheading, styles.placement)}>1.&nbsp;místo</h4>
              3&nbsp;000&nbsp;Kč + pohár
            </li>
            <li>
              <h4 className={clsx(generalStyles.subheading, styles.placement)}>2.&nbsp;místo</h4>
              1&nbsp;500&nbsp;Kč + pohár
            </li>
            <li>
              <h4 className={clsx(generalStyles.subheading, styles.placement)}>3.&nbsp;místo</h4>
              700&nbsp;Kč + pohár
            </li>
          </ol>
          <p className={styles.note}>Všichni účastníci finále dostanou trička v&nbsp;prodejní hodnotě 500&nbsp;Kč.</p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Detaily turnaje</h3>
          <dl className={clsx(styles.defList, styles.center)}>
            <dt>Hra:</dt>
            <dd>StarCraft: Brood War</dd>
            <dt>Datum:</dt>
            <dd>28. září 2024</dd>
            <dt>Čas:</dt>
            <dd>9:00 - 21:00</dd>
            <dt>Zahájení akce:</dt>
            <dd>10:00</dd>
            <dt>Místo:</dt>
            <dd>
              <Link to="https://magentaexperience.cz/" className="text-underline" target="magentaexperiencecenter">
                Magenta Experience Center, Praha
              </Link>
            </dd>
            <dt>Vstup pro diváky:</dt>
            <dd className={styles.highlight}>Zdarma!</dd>
            <dt>Parkování:</dt>
            <dd>V rámci OC Arkády</dd>
          </dl>
          <p className={styles.danger}>
            Do Magenta Experience Centra &nbsp;
            <Link
              to="https://magentaexperience.cz/kudy-k-nam.html"
              className="text-underline"
              target="magentaexperiencecenter"
            >
              vstupujte venkem z terasy – z ulice Hvězdova (mimo OC)
            </Link>{' '}
          </p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Unikátní prostory</h3>
          <p>
            Magenta Experience Center (Praha, OC Arkády) je domovem moderních technologií a skvělým zázemím pro finále
            CZSK StarCraft ligy. Díky několika velkoplošným obrazovkám budete moci pohodlně sledovat finálové zápasy. Na
            místě je možnost občerstvení ve formě kávy a nealkoholických nápojů. Vstup zdarma!
          </p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Živě komentované zápasy</h3>
          <p>
            Fanoušci si užijí zápasy s profesionálním komentářem jak přímo na místě, tak online na platformě Twitch.
          </p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Bohatý doprovodný program</h3>
          <p>
            Mimo hlavního turnaje budou pro Vás připravené různé doprovodné aktivity. Můžete si vyzkoušet komentovat
            zápas, nebo se postavit do role hráče a vyzkoušet si atmosféru na vlastní kůži! Vaši hru okomentují naši
            zkušení komentátoři. Dále pro Vás budou připravené různé tematické kvízy a mnoho dalších aktivit!
          </p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Setkání hráčů a fanoušků</h3>
          <p>
            Budete mít možnost setkat se s nejlepšími českými a slovenskými hráči. Dostatek prostoru bude i pro
            společenské setkání s ostatními fanoušky této legendární hry.
          </p>
        </section>

        <section className={styles.section}>
          <h3 className={generalStyles.subheading}>Harmonogram turnaje</h3>
          <ul className={clsx(styles.list, styles.center)}>
            <li>
              <strong>Skupinová fáze (10:00 - 13:00)</strong>
              <ul>
                <li>2 skupiny (A, B) po 4 hráčích</li>
                <li>Všechny zápasy BO3 (na 2 vítězné mapy)</li>
                <li>Z každé skupiny postupují 2 hráči do play-off</li>
              </ul>
            </li>
            <li>
              <strong>Pauza na oběd (13:00 - 14:00)</strong>
            </li>
            <li>
              <strong>Semifinále (14:00 - 16:30)</strong>
              <ul>
                <li>A1 vs B2, A2 vs B1</li>
                <li>BO5 (na 3 vítězné mapy)</li>
              </ul>
            </li>
            <li>
              <strong>Grand finále (17:00 - 20:00)</strong>
              <ul>
                <li>BO5 (na 3 vítězné mapy)</li>
              </ul>
            </li>
          </ul>
        </section>

        <LinkList
          items={[
            { name: 'Skupina A', to: Routes.S5_FINALS_GROUP_A.path },
            { name: 'Skupina B', to: Routes.S5_FINALS_GROUP_B.path },
            {
              name: (
                <>
                  Pla<span className={generalStyles.emptyChar}></span>y-off
                </>
              ),
              to: Routes.S5_FINALS_PLAYOFF.path,
            },
          ]}
        />
        <Footer />
      </main>
    </div>
  );
};
