import { Finals } from './pages/s5/Finals';
import { GroupA } from './pages/s5/GroupA';
import { GroupB } from './pages/s5/GroupB';
import { Playoff } from './pages/s5/Playoff';
import { QualificationGroupBlue } from './pages/s5/QualificationGroupBlue';
import { QualificationGroupRed } from './pages/s5/QualificationGroupRed';
import { RookieTourGroupA } from './pages/s5/RookieTourGroupA';
import { RookieTourGroupB } from './pages/s5/RookieTourGroupB';
import { RookieTourPlayoff } from './pages/s5/RookieTourPlayoff';

const S5_BASE = '/5-sezona';
const S5_PRELIMINARY_BASE = `${S5_BASE}/predkolo-finale`;
const S5_ROOKIE_BASE = `${S5_BASE}/rookie-tour`;

export const Routes = Object.freeze({
  S5_FINALS: {
    path: `${S5_BASE}/offline-finale`,
    component: <Finals />,
    index: true,
  },
  S5_FINALS_GROUP_A: {
    path: `${S5_BASE}/offline-finale/skupina-a`,
    component: <GroupA />,
  },
  S5_FINALS_GROUP_B: {
    path: `${S5_BASE}/offline-finale/skupina-b`,
    component: <GroupB />,
  },
  S5_FINALS_PLAYOFF: {
    path: `${S5_BASE}/offline-finale/play-off`,
    component: <Playoff />,
  },
  S5_PRELIMINARY_RED: {
    path: `${S5_PRELIMINARY_BASE}/red-group`,
    component: <QualificationGroupRed />,
  },
  S5_PRELIMINARY_BLUE: {
    path: `${S5_PRELIMINARY_BASE}/blue-group`,
    component: <QualificationGroupBlue />,
  },
  S5_ROOKIE_TOUR_GROUP_A: {
    path: `${S5_ROOKIE_BASE}/skupina-a`,
    component: <RookieTourGroupA />,
  },
  S5_ROOKIE_TOUR_GROUP_B: {
    path: `${S5_ROOKIE_BASE}/skupina-b`,
    component: <RookieTourGroupB />,
  },
  S5_ROOKIE_TOUR_PLAYOFF: {
    path: `${S5_ROOKIE_BASE}/play-off`,
    component: <RookieTourPlayoff />,
  },
  S5_GROUP_STAGE: {
    path: `${S5_BASE}/zakladni-cast`,
    component: <></>,
  },
});
