// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e99BENsQvJOEc5qVaw1R {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 90vw;
}

.fnojKAfmExV5JVlwa6TE {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/videoModal.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AADF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeWrapper": `e99BENsQvJOEc5qVaw1R`,
	"video": `fnojKAfmExV5JVlwa6TE`
};
export default ___CSS_LOADER_EXPORT___;
